import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';

const TestimonialCard = ({ quote, author, position }) => (
  <div className="bg-white p-6 rounded-lg shadow-lg">
    <div className="text-[#003A9B] text-2xl mb-4">
      <FontAwesomeIcon icon={faQuoteLeft} />
    </div>
    <p className="text-gray-600 italic mb-4">{quote}</p>
    <div>
      <p className="font-semibold text-gray-900">{author}</p>
      <p className="text-gray-500 text-sm">{position}</p>
    </div>
  </div>
);

const Testimonials = () => {
  const testimonials = [
    {
      quote: "WorkFission has transformed how we manage our part-time workforce. The platform is intuitive and has saved us countless hours in scheduling and payroll.",
      author: "Sarah Johnson",
      position: "HR Director, TechCorp"
    },
    {
      quote: "As a student, finding flexible part-time work was always challenging. WorkFission made it easy to find jobs that fit perfectly with my schedule.",
      author: "Michael Chen",
      position: "Part-time Professional"
    }
  ];

  return (
    <section className="py-16 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900">Trusted by Businesses</h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard key={index} {...testimonial} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
