import React from 'react';
import { useNavigate } from 'react-router-dom';

const CallToAction = () => {
  const navigate = useNavigate();

  return (
    <section className="py-16 bg-[#1C4E80] text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-3xl font-bold mb-8">Start Hiring or Find a Job Today</h2>
        <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
          <button
            onClick={() => navigate('/employer-signin')}
            className="bg-[#FF6600] text-white px-8 py-3 rounded-md hover:bg-[#ff7519] transition duration-300"
          >
            Start Hiring Now
          </button>
          <button
            onClick={() => navigate('/user-signin')}
            className="bg-white text-[#1C4E80] px-8 py-3 rounded-md hover:bg-gray-100 transition duration-300"
          >
            Find a Job
          </button>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;
