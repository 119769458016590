import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDFwpEXGtjAlBXzSKZB1R58A_udLIwKtFo",
  authDomain: "workfission-3e34c.firebaseapp.com",
  projectId: "workfission-3e34c",
  storageBucket: "workfission-3e34c.firebasestorage.app",
  messagingSenderId: "212955370489",
  appId: "1:212955370489:web:8c83c05c6421ae2ca3e328",
  measurementId: "G-5BHQNBL7T7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);

export default app; 