import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LandingPage from './components/landing/LandingPage';
import SignIn from './components/auth/SignIn';
import EmployerDashboard from './pages/dashboard/EmployerDashboard';
import UserDashboard from './pages/dashboard/UserDashboard';
import About from './pages/About';
import ErrorBoundary from './components/ErrorBoundary';
import './App.css';

function App() {
  return (
    <ErrorBoundary>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/employer-signin" element={<SignIn />} />
          <Route path="/user-signin" element={<SignIn />} />
          <Route path="/employer-dashboard" element={<EmployerDashboard />} />
          <Route path="/user-dashboard" element={<UserDashboard />} />
          <Route path="/contact" element={<Navigate to="/" />} />
          <Route path="/faqs" element={<Navigate to="/" />} />
          <Route path="/privacy" element={<Navigate to="/" />} />
          <Route path="/terms" element={<Navigate to="/" />} />
          <Route path="/cookies" element={<Navigate to="/" />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
