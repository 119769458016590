import React, { useState } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const navLinks = [
    { name: 'Home', to: 'hero' },
    { name: 'Why WorkFission', to: 'benefits' },
    { name: 'How It Works', to: 'how-it-works' }
  ];

  return (
    <nav className="sticky top-0 z-50 bg-[#003A9B] text-white shadow-lg">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <div className="flex-shrink-0">
            <RouterLink to="/" className="text-xl font-bold">
              WorkFission
            </RouterLink>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:block">
            <div className="ml-10 flex items-center space-x-4">
              {navLinks.map((link) => (
                <ScrollLink
                  key={link.name}
                  to={link.to}
                  smooth={true}
                  duration={500}
                  className="cursor-pointer hover:text-gray-300 px-3 py-2"
                >
                  {link.name}
                </ScrollLink>
              ))}
            </div>
          </div>

          {/* Auth Buttons */}
          <div className="hidden md:block">
            <div className="flex items-center space-x-4">
              <RouterLink
                to="/user-signin"
                className="bg-white text-[#003A9B] px-4 py-2 rounded-md hover:bg-gray-100"
              >
                Sign In
              </RouterLink>
              <RouterLink
                to="/employer-signin"
                className="bg-[#FF6600] px-4 py-2 rounded-md hover:bg-[#ff7519]"
              >
                Employer Sign In
              </RouterLink>
            </div>
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md hover:bg-[#0046bd]"
            >
              <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div className={`${isOpen ? 'block' : 'hidden'} md:hidden`}>
        <div className="px-2 pt-2 pb-3 space-y-1">
          {navLinks.map((link) => (
            <ScrollLink
              key={link.name}
              to={link.to}
              smooth={true}
              duration={500}
              className="block px-3 py-2 rounded-md hover:bg-[#0046bd] cursor-pointer"
              onClick={() => setIsOpen(false)}
            >
              {link.name}
            </ScrollLink>
          ))}
          <RouterLink
            to="/user-signin"
            className="block w-full text-left px-3 py-2 rounded-md hover:bg-[#0046bd]"
            onClick={() => setIsOpen(false)}
          >
            Sign In
          </RouterLink>
          <RouterLink
            to="/employer-signin"
            className="block w-full text-left px-3 py-2 rounded-md hover:bg-[#0046bd]"
            onClick={() => setIsOpen(false)}
          >
            Employer Sign In
          </RouterLink>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
