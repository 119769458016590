import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../../firebase/firebaseConfig';
import { 
  signInWithPopup, 
  GoogleAuthProvider, 
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink
} from 'firebase/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import AuthLayout from './AuthLayout';

const SignIn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isEmployer = location.pathname === '/employer-signin';
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [signInError, setSignInError] = useState('');
  const [emailSent, setEmailSent] = useState(false);

  // Check for sign-in link on component mount
  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let storedEmail = localStorage.getItem('emailForSignIn');
      
      if (!storedEmail) {
        // If email is not in storage, prompt user
        const userEmail = window.prompt('Please provide your email for confirmation:');
        if (!userEmail) {
          setSignInError('Email is required to complete sign in.');
          return;
        }
        storedEmail = userEmail;
      }

      signInWithEmailLink(auth, storedEmail, window.location.href)
        .then((result) => {
          localStorage.removeItem('emailForSignIn');
          if (result.user) {
            navigate(isEmployer ? '/employer-dashboard' : '/user-dashboard');
          }
        })
        .catch((error) => {
          console.error('Error signing in with email link:', error);
          setSignInError('Failed to complete sign-in. Please try again.');
          localStorage.removeItem('emailForSignIn');
        });
    }
  }, [navigate, isEmployer]);

  const handleGoogleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      
      if (result.user) {
        navigate(isEmployer ? '/employer-dashboard' : '/user-dashboard');
      }
    } catch (error) {
      console.error('Error signing in with Google:', error);
      setSignInError('Failed to sign in with Google. Please try again.');
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    if (value && !validateEmail(value)) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError('');
    }
    setSignInError('');
  };

  const handleSendLink = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      return;
    }

    const actionCodeSettings = {
      url: window.location.href,
      handleCodeInApp: true
    };

    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      localStorage.setItem('emailForSignIn', email);
      setEmailSent(true);
      setSignInError('');
    } catch (error) {
      console.error('Error sending sign-in link:', error);
      setSignInError('Failed to send sign-in link. Please try again.');
    }
  };

  return (
    <AuthLayout
      title={isEmployer ? "Sign in as an Employer" : "Ready to take the next step?"}
      subtitle="Create an account or sign in."
    >
      {/* Google Sign-In Button */}
      <button
        onClick={handleGoogleSignIn}
        className="w-full flex justify-center items-center py-3 px-4 rounded-md shadow-sm text-white bg-[#003A9B] hover:bg-[#0046bd] transition duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#003A9B]"
        aria-label="Continue with Google"
      >
        <FontAwesomeIcon icon={faGoogle} className="h-5 w-5 mr-2" />
        Continue with Google
      </button>

      <div className="mt-6">
        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 bg-white text-gray-500">Or sign in with email link</span>
          </div>
        </div>

        <form onSubmit={handleSendLink} className="mt-6 space-y-6">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email address *
            </label>
            <div className="mt-1">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={handleEmailChange}
                className={`appearance-none block w-full px-3 py-2 border ${
                  emailError ? 'border-red-300' : 'border-gray-300'
                } rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#003A9B] focus:border-[#003A9B] sm:text-sm`}
                placeholder="Enter your email address"
                aria-label="Email address"
                aria-invalid={!!emailError}
                aria-describedby={emailError ? "email-error" : undefined}
              />
              {emailError && (
                <p className="mt-2 text-sm text-red-600" id="email-error" role="alert">
                  {emailError}
                </p>
              )}
            </div>
          </div>

          {signInError && (
            <div className="rounded-md bg-red-50 p-4">
              <p className="text-sm text-red-600" role="alert">
                {signInError}
              </p>
            </div>
          )}

          {emailSent ? (
            <div className="rounded-md bg-green-50 p-4">
              <p className="text-sm text-green-600" role="alert">
                Sign-in link sent! Please check your email to complete sign-in.
              </p>
            </div>
          ) : (
            <button
              type="submit"
              disabled={!email || !!emailError}
              className={`w-full flex justify-center items-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                !email || !!emailError
                  ? 'bg-gray-300 cursor-not-allowed'
                  : 'bg-[#003A9B] hover:bg-[#0046bd]'
              } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#003A9B] transition duration-300`}
              aria-label="Send sign-in link"
            >
              Send Sign-In Link →
            </button>
          )}
        </form>
      </div>
    </AuthLayout>
  );
};

export default SignIn; 