import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase/firebaseConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faSearch, faBriefcase, faCalendarAlt, faDollarSign, 
  faLocationDot, faClock, faBuilding, faStar, faMicrophone,
  faExclamationTriangle, faBan, faBed, faLightbulb, faCheckCircle,
  faExclamationCircle, faExchange, faChartLine, faCalendarCheck,
  faFire, faCoins, faCommentDollar, faTimes,
  faPaperPlane, faRobot, faArrowUp,
  faBell,
  faUser,
  faSignOutAlt
} from '@fortawesome/free-solid-svg-icons';

// Mock data for demo purposes
const MOCK_JOBS = [
  {
    id: 'job1',
    title: 'Barista',
    company: 'Local Coffee Shop',
    location: 'San Francisco, CA',
    distance: '0.8 miles away',
    type: 'Part-time',
    pay: '$18-22/hr',
    skills: ['Customer Service', 'Food Preparation', 'Cash Handling'],
    schedule: 'Flexible, Weekends',
    rating: 4.7,
    posted: '2 days ago'
  },
  {
    id: 'job2',
    title: 'Delivery Driver',
    company: 'FastFood Express',
    location: 'San Francisco, CA',
    distance: '1.2 miles away',
    type: 'Part-time',
    pay: '$22-25/hr',
    skills: ['Driving', 'Navigation', 'Time Management'],
    schedule: 'Evenings, Weekends',
    rating: 4.2,
    posted: 'Today'
  },
  {
    id: 'job3',
    title: 'Retail Associate',
    company: 'Fashion Outlet',
    location: 'San Francisco, CA',
    distance: '1.5 miles away',
    type: 'Part-time',
    pay: '$17-20/hr',
    skills: ['Customer Service', 'Sales', 'Inventory Management'],
    schedule: 'Weekdays, Some Weekends',
    rating: 4.0,
    posted: '3 days ago'
  },
  {
    id: 'job4',
    title: 'Web Developer (Remote)',
    company: 'Tech Solutions Inc.',
    location: 'Remote',
    distance: 'Work from home',
    type: 'Contract',
    pay: '$40-50/hr',
    skills: ['React', 'JavaScript', 'CSS'],
    schedule: 'Flexible hours',
    rating: 4.9,
    posted: '1 day ago'
  },
  {
    id: 'job5',
    title: 'Restaurant Server',
    company: 'Gourmet Dining',
    location: 'San Francisco, CA',
    distance: '2.3 miles away',
    type: 'Part-time',
    pay: '$15-18/hr + tips',
    skills: ['Customer Service', 'Food Service', 'Team Player'],
    schedule: 'Evenings, Weekends',
    rating: 4.3,
    posted: '5 days ago'
  }
];

const MOCK_UPCOMING_SHIFTS = [
  {
    id: 'shift1',
    jobTitle: 'Barista',
    company: 'Local Coffee Shop',
    date: 'Mon, Apr 15, 2024',
    time: '9:00 AM - 2:00 PM',
    pay: '$18/hr',
    totalPay: '$90.00',
    status: 'Confirmed'
  },
  {
    id: 'shift2',
    jobTitle: 'Barista',
    company: 'Local Coffee Shop',
    date: 'Wed, Apr 17, 2024',
    time: '10:00 AM - 3:00 PM',
    pay: '$18/hr',
    totalPay: '$90.00',
    status: 'Confirmed'
  },
  {
    id: 'shift3',
    jobTitle: 'Delivery Driver',
    company: 'FastFood Express',
    date: 'Fri, Apr 19, 2024',
    time: '5:00 PM - 10:00 PM',
    pay: '$22/hr + tips',
    totalPay: '$110.00 (estimated)',
    status: 'Pending'
  }
];

// Mock payment data
const MOCK_PAYMENT_HISTORY = [
  {
    id: 'payment1',
    date: 'Apr 10, 2024',
    company: 'Local Coffee Shop',
    hours: 15,
    amount: '$270.00',
    basePay: '$240.00',
    tips: '$30.00',
    overtime: '$0.00',
    deductions: '$0.00',
    status: 'Paid',
    method: 'Direct Deposit'
  },
  {
    id: 'payment2',
    date: 'Apr 3, 2024',
    company: 'FastFood Express',
    hours: 12,
    amount: '$264.00',
    basePay: '$240.00',
    tips: '$24.00',
    overtime: '$0.00',
    deductions: '$0.00',
    status: 'Paid',
    method: 'Direct Deposit'
  },
  {
    id: 'payment3',
    date: 'Mar 27, 2024',
    company: 'Local Coffee Shop',
    hours: 20,
    amount: '$360.00',
    basePay: '$320.00',
    tips: '$40.00',
    overtime: '$0.00',
    deductions: '$0.00',
    status: 'Paid',
    method: 'Direct Deposit'
  }
];

// Mock current earnings
const CURRENT_EARNINGS = {
  period: 'Apr 14 - Apr 20, 2024',
  currentAmount: '$248.50',
  expectedTotal: '$468.50',
  hoursWorked: 11,
  hoursScheduled: 21,
  shiftsCompleted: 2,
  shiftsRemaining: 2,
  breakdown: {
    basePay: '$198.00',
    tips: '$38.50',
    overtime: '$12.00'
  },
  lastUpdated: '2 hours ago'
};

// AI payment insights
const PAYMENT_INSIGHTS = [
  {
    id: 'insight1',
    type: 'discrepancy',
    title: 'Possible Missing Hours',
    message: 'Your timecard shows 22 hours but your Apr 3 payment reflects only 19 hours. Would you like to flag this for review?',
    actions: ['Flag for Review', 'Dismiss'],
    severity: 'high',
    isRead: false
  },
  {
    id: 'insight2',
    type: 'forecast',
    title: 'Earnings Forecast',
    message: 'Based on your current schedule, you\'re projected to earn $1,872 in April, which is 15% higher than March.',
    actions: ['View Details', 'Got It'],
    severity: 'low',
    isRead: false
  },
  {
    id: 'insight3',
    type: 'tip',
    title: 'Tip Optimization',
    message: 'You earn 22% more in tips during weekend evening shifts compared to weekday mornings. Consider requesting more weekend shifts to increase your earnings.',
    actions: ['Find Weekend Shifts', 'Noted'],
    severity: 'medium',
    isRead: true
  },
  {
    id: 'insight4',
    type: 'instant',
    title: 'Instant Pay Available',
    message: 'You\'ve earned $248.50 so far this week. Would you like to access up to $200 before your regular payday?',
    actions: ['Get Instant Pay', 'Not Now'],
    severity: 'medium',
    isRead: false
  }
];

// Mock earnings forecast
const EARNINGS_FORECAST = {
  currentMonth: 'April',
  projectedAmount: '$1,872',
  lastMonth: {
    name: 'March',
    amount: '$1,630'
  },
  nextMonth: {
    name: 'May',
    amount: '$1,950',
    confidence: '85%'
  },
  projectedByJob: [
    {
      company: 'Local Coffee Shop',
      amount: '$1,080',
      percentage: 58
    },
    {
      company: 'FastFood Express', 
      amount: '$792',
      percentage: 42
    }
  ],
  savingsGoal: {
    target: '$2,000',
    progress: '$1,872',
    shortfall: '$128'
  }
};

// AI Search suggestions for demo
const AI_SEARCH_SUGGESTIONS = [
  "What are the jobs near my area?",
  "Find part-time jobs with my skills",
  "Weekend jobs in San Francisco",
  "Remote work opportunities",
  "Flexible schedule positions near me"
];

// Mock AI scheduling notifications
const AI_SCHEDULE_NOTIFICATIONS = [
  {
    id: 'notif1',
    type: 'conflict',
    severity: 'high',
    title: 'Schedule Conflict Detected',
    message: 'Your Coffee Shop shift on Friday overlaps with your Delivery Driver shift. Would you like me to suggest alternative options?',
    actions: ['Resolve Conflict', 'Ignore'],
    isRead: false
  },
  {
    id: 'notif2',
    type: 'cancellation',
    severity: 'medium',
    title: 'Shift Canceled - Recovery Options',
    message: 'Your Retail Associate shift for Thursday was canceled. I found 3 similar shifts nearby that match your skills and availability.',
    actions: ['View Options', 'Dismiss'],
    isRead: false
  },
  {
    id: 'notif3',
    type: 'fatigue',
    severity: 'medium',
    title: 'Rest Period Alert',
    message: 'You have back-to-back shifts with only 7 hours between them. This may impact your rest. Would you like to request a schedule adjustment?',
    actions: ['Adjust Schedule', 'I\'m Fine'],
    isRead: false
  },
  {
    id: 'notif4',
    type: 'suggestion',
    severity: 'low',
    title: 'Schedule Optimization',
    message: 'Based on your preferences, I can help you build a more consistent weekly schedule with higher-paying shifts. Want to see my suggestions?',
    actions: ['Show Me', 'Later'],
    isRead: true
  }
];

// Mock schedule conflicts
const SCHEDULE_CONFLICTS = [
  {
    id: 'conflict1',
    shifts: [
      {
        id: 'shift2',
        jobTitle: 'Barista',
        company: 'Local Coffee Shop',
        date: 'Wed, Apr 17, 2024',
        time: '10:00 AM - 3:00 PM'
      },
      {
        id: 'conflict-shift1',
        jobTitle: 'Retail Associate',
        company: 'Fashion Outlet',
        date: 'Wed, Apr 17, 2024',
        time: '12:00 PM - 5:00 PM'
      }
    ],
    resolution: 'Keep Coffee Shop shift and find replacement for Retail shift'
  }
];

// Mock alternative shifts
const ALTERNATIVE_SHIFTS = [
  {
    id: 'alt1',
    jobTitle: 'Barista',
    company: 'Urban Coffee Co.',
    date: 'Thu, Apr 18, 2024',
    time: '1:00 PM - 6:00 PM',
    pay: '$19/hr',
    distance: '1.2 miles away',
    match: '95% match'
  },
  {
    id: 'alt2',
    jobTitle: 'Cashier',
    company: 'Grocery Mart',
    date: 'Thu, Apr 18, 2024',
    time: '4:00 PM - 9:00 PM',
    pay: '$17/hr',
    distance: '0.8 miles away',
    match: '85% match'
  },
  {
    id: 'alt3',
    jobTitle: 'Server',
    company: 'Riverside Café',
    date: 'Thu, Apr 18, 2024',
    time: '5:00 PM - 10:00 PM',
    pay: '$16/hr + tips',
    distance: '1.5 miles away',
    match: '80% match'
  }
];

// AI-powered schedule insights
const SCHEDULE_INSIGHTS = {
  hoursWorked: 22,
  expectedPay: '$486.00',
  shifts: 4,
  consistency: 'Medium',
  recommendedHours: '25-30',
  restPeriods: 'Sufficient',
  burnoutRisk: 'Low',
  incomeProjection: '$1,950/month',
  nextWeekForecast: '5 shifts available'
};

// Chatbot sample responses
const CHATBOT_RESPONSES = {
  greeting: [
    "Hi there! I'm your WorkFission AI assistant. How can I help you today?",
    "Hello! I'm here to help with any questions about your pay, schedule, or jobs. What would you like to know?"
  ],
  fallback: [
    "I'm not sure I understand. Could you rephrase that or ask about your pay, schedule, or job opportunities?",
    "I don't have enough information to answer that. Try asking about your earnings, upcoming payments, or work schedule."
  ],
  pay_current: `Based on your completed shifts, you've earned $248.50 so far this pay period. You have 2 more shifts scheduled, which would bring your total to approximately $468.50.`,
  pay_last: `Your last payment was $270.00 from Local Coffee Shop on April 10, 2024. This included $240.00 base pay and $30.00 in tips.`,
  pay_next: `Your next payment is scheduled for April 24, 2024. Based on your current and scheduled shifts, you can expect approximately $468.50.`,
  pay_tips: `You've earned $38.50 in tips so far this pay period. On average, you earn 22% more in tips during weekend evening shifts compared to weekday shifts.`,
  pay_history: `In the last month, you've earned a total of $894.00 across 3 payments - $270.00 on April 10, $264.00 on April 3, and $360.00 on March 27.`,
  pay_hourly: `Your current hourly rates are: $18/hr at Local Coffee Shop and $22/hr at FastFood Express. With tips, your effective rates average $20-24/hr.`,
  pay_taxes: `Based on your current earnings, I estimate approximately 15-20% of your income will go to taxes. Consider speaking with a tax professional for advice specific to your situation.`,
  pay_instant: `You have $200.00 available for instant withdrawal from your current earnings of $248.50. There's a $1.99 fee for instant access.`,
  schedule_upcoming: `You have 4 upcoming shifts: Monday (Apr 15) and Wednesday (Apr 17) at Local Coffee Shop, and Friday (Apr 19) at FastFood Express. There's also a schedule conflict on Wednesday that needs resolution.`,
  schedule_hours: `You're scheduled for 21 hours this week: 10 hours at Local Coffee Shop and 11 hours at FastFood Express.`,
  jobs_recommended: `Based on your skills and preferences, I recommend checking out the Barista position at Urban Coffee Co. ($19/hr) and the Web Developer role at Tech Solutions ($40-50/hr).`,
  help: `I can help with information about your pay (earnings, history, next payment), schedule (upcoming shifts, hours), and job recommendations. Just ask!`
};

// Add this after the MOCK_PAYMENT_HISTORY constant
const EARNINGS_DATA = {
  'Last 12 Months': [
    { month: 'Jan', earnings: 1450, height: 0.5 },
    { month: 'Feb', earnings: 1200, height: 0.4 },
    { month: 'Mar', earnings: 1850, height: 0.6 },
    { month: 'Apr', earnings: 2100, height: 0.7 },
    { month: 'May', earnings: 1750, height: 0.55 },
    { month: 'Jun', earnings: 1300, height: 0.45 },
    { month: 'Jul', earnings: 950, height: 0.3 },
    { month: 'Aug', earnings: 1500, height: 0.5 },
    { month: 'Sep', earnings: 1900, height: 0.65 },
    { month: 'Oct', earnings: 1650, height: 0.55 },
    { month: 'Nov', earnings: 2000, height: 0.7 },
    { month: 'Dec', earnings: 1400, height: 0.45 }
  ],
  'Last 6 Months': [
    { month: 'Jul', earnings: 1800, height: 0.6 },
    { month: 'Aug', earnings: 2100, height: 0.7 },
    { month: 'Sep', earnings: 1950, height: 0.65 },
    { month: 'Oct', earnings: 1650, height: 0.55 },
    { month: 'Nov', earnings: 2200, height: 0.75 },
    { month: 'Dec', earnings: 1900, height: 0.63 }
  ],
  'This Year': [
    { month: 'Q1', earnings: 2250, height: 0.75 },
    { month: 'Q2', earnings: 2750, height: 0.92 },
    { month: 'Q3', earnings: 1950, height: 0.65 },
    { month: 'Q4', earnings: 2900, height: 0.97 }
  ]
};

const JobCard = ({ job }) => (
  <div className="bg-white rounded-lg shadow-sm p-4 hover:shadow-md transition-shadow duration-300 border border-gray-100">
    <div className="flex justify-between items-start">
      <div>
        <h3 className="text-lg font-semibold text-gray-900">{job.title}</h3>
        <p className="text-gray-600 text-sm flex items-center mt-1">
          <FontAwesomeIcon icon={faBuilding} className="mr-2 text-gray-400" />
          {job.company}
        </p>
        <p className="text-gray-600 text-sm flex items-center mt-1">
          <FontAwesomeIcon icon={faLocationDot} className="mr-2 text-gray-400" />
          {job.location} • <span className="ml-1 text-gray-500">{job.distance}</span>
        </p>
      </div>
      <div className="flex flex-col items-end">
        <span className="bg-blue-100 text-blue-800 px-2 py-1 rounded-full text-xs font-medium">
          {job.type}
        </span>
        <div className="flex items-center mt-2 text-yellow-500">
          <FontAwesomeIcon icon={faStar} />
          <span className="ml-1 text-sm">{job.rating}</span>
        </div>
      </div>
    </div>
    
    <div className="mt-3">
      <div className="flex items-center text-gray-700">
        <FontAwesomeIcon icon={faDollarSign} className="mr-2 text-green-600" />
        <span className="font-medium text-gray-900">{job.pay}</span>
      </div>
      <div className="flex items-center text-gray-700 mt-1">
        <FontAwesomeIcon icon={faClock} className="mr-2 text-blue-600" />
        <span>{job.schedule}</span>
      </div>
    </div>
    
    <div className="mt-3">
      <div className="flex flex-wrap gap-1">
        {job.skills.map((skill, index) => (
          <span 
            key={index} 
            className="bg-gray-100 text-gray-800 text-xs px-2 py-1 rounded"
          >
            {skill}
          </span>
        ))}
      </div>
    </div>
    
    <div className="mt-4 flex justify-between items-center">
      <span className="text-xs text-gray-500">Posted {job.posted}</span>
      <button className="bg-[#003A9B] text-white px-4 py-2 rounded-md text-sm hover:bg-[#0046bd] transition-colors duration-300">
        Apply Now
      </button>
    </div>
  </div>
);

const ShiftCard = ({ shift }) => (
  <div className="bg-white rounded-lg shadow-sm p-4 border border-gray-100">
    <div className="flex justify-between items-start">
      <div>
        <h3 className="font-semibold text-gray-900">{shift.jobTitle}</h3>
        <p className="text-gray-600 text-sm">{shift.company}</p>
      </div>
      <span className={`px-2 py-1 rounded-full text-xs font-medium ${
        shift.status === 'Confirmed' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
      }`}>
        {shift.status}
      </span>
    </div>
    
    <div className="mt-3 grid grid-cols-2 gap-2">
      <div>
        <p className="text-xs text-gray-500">Date</p>
        <p className="text-sm">{shift.date}</p>
      </div>
      <div>
        <p className="text-xs text-gray-500">Time</p>
        <p className="text-sm">{shift.time}</p>
      </div>
      <div>
        <p className="text-xs text-gray-500">Pay Rate</p>
        <p className="text-sm">{shift.pay}</p>
      </div>
      <div>
        <p className="text-xs text-gray-500">Estimated Total</p>
        <p className="text-sm font-medium text-gray-900">{shift.totalPay}</p>
      </div>
    </div>
  </div>
);

const PaymentCard = ({ payment }) => (
  <div className="bg-white rounded-lg shadow-sm p-4 border border-gray-100">
    <div className="flex justify-between items-start">
      <div>
        <p className="text-gray-600 text-sm">{payment.date}</p>
        <h3 className="font-semibold text-gray-900">{payment.company}</h3>
      </div>
      <span className="bg-green-100 text-green-800 px-2 py-1 rounded-full text-xs font-medium">
        {payment.status}
      </span>
    </div>
    
    <div className="mt-3 grid grid-cols-3 gap-2">
      <div>
        <p className="text-xs text-gray-500">Hours</p>
        <p className="text-sm">{payment.hours} hrs</p>
      </div>
      <div>
        <p className="text-xs text-gray-500">Amount</p>
        <p className="text-sm font-medium text-gray-900">{payment.amount}</p>
      </div>
      <div>
        <p className="text-xs text-gray-500">Method</p>
        <p className="text-sm">{payment.method}</p>
      </div>
    </div>
  </div>
);

const NotificationCard = ({ notification, onAction }) => {
  const getSeverityColor = (severity) => {
    switch (severity) {
      case 'high':
        return 'bg-red-50 border-red-200';
      case 'medium':
        return 'bg-orange-50 border-orange-200';
      default:
        return 'bg-blue-50 border-blue-200';
    }
  };

  const getIconColor = (severity) => {
    switch (severity) {
      case 'high':
        return 'text-red-500';
      case 'medium':
        return 'text-orange-500';
      default:
        return 'text-blue-500';
    }
  };

  const getIcon = (type) => {
    switch (type) {
      case 'conflict':
        return faExclamationTriangle;
      case 'cancellation':
        return faBan;
      case 'fatigue':
        return faBed;
      default:
        return faLightbulb;
    }
  };

  return (
    <div className={`${getSeverityColor(notification.severity)} border rounded-lg p-4 mb-3 ${notification.isRead ? 'opacity-75' : ''}`}>
      <div className="flex items-start">
        <div className={`${getIconColor(notification.severity)} mr-3 mt-1`}>
          <FontAwesomeIcon icon={getIcon(notification.type)} size="lg" />
        </div>
        <div className="flex-1">
          <h4 className="font-medium text-gray-900">{notification.title}</h4>
          <p className="text-sm text-gray-700 mt-1">{notification.message}</p>
          <div className="flex mt-3 space-x-2">
            {notification.actions.map((action, index) => (
              <button
                key={index}
                onClick={() => onAction(notification.id, action)}
                className={index === 0 
                  ? "px-3 py-1 bg-[#003A9B] text-white text-xs rounded hover:bg-[#0046bd]"
                  : "px-3 py-1 bg-gray-100 text-gray-700 text-xs rounded hover:bg-gray-200"
                }
              >
                {action}
              </button>
            ))}
          </div>
        </div>
        {!notification.isRead && (
          <div className="h-2 w-2 bg-blue-500 rounded-full"></div>
        )}
      </div>
    </div>
  );
};

const ConflictResolutionCard = ({ conflict }) => (
  <div className="bg-red-50 border border-red-200 rounded-lg p-4 mb-4">
    <h4 className="font-medium text-gray-900 mb-3">Schedule Conflict</h4>
    
    <div className="flex flex-col space-y-3 md:flex-row md:space-y-0 md:space-x-4 mb-4">
      {conflict.shifts.map((shift, index) => (
        <div 
          key={index} 
          className={`bg-white rounded-md p-3 border ${index === 0 ? 'border-green-300' : 'border-red-300'} flex-1`}
        >
          <p className="font-medium text-gray-900">{shift.jobTitle}</p>
          <p className="text-sm text-gray-700">{shift.company}</p>
          <p className="text-sm text-gray-600 mt-2">{shift.date}</p>
          <p className="text-sm text-gray-600">{shift.time}</p>
          {index === 0 && (
            <div className="mt-2 inline-block bg-green-100 text-green-800 text-xs px-2 py-1 rounded">
              Recommended to Keep
            </div>
          )}
          {index === 1 && (
            <div className="mt-2 inline-block bg-red-100 text-red-800 text-xs px-2 py-1 rounded">
              Scheduling Conflict
            </div>
          )}
        </div>
      ))}
    </div>
    
    <div className="bg-white p-3 rounded-md border border-blue-200">
      <p className="text-sm font-medium text-gray-900">AI Recommendation:</p>
      <p className="text-sm text-gray-700">{conflict.resolution}</p>
    </div>
    
    <div className="flex justify-end mt-3 space-x-2">
      <button className="px-3 py-1 bg-gray-100 text-gray-700 text-sm rounded hover:bg-gray-200">
        Make Different Choice
      </button>
      <button className="px-3 py-1 bg-[#003A9B] text-white text-sm rounded hover:bg-[#0046bd]">
        Accept Recommendation
      </button>
    </div>
  </div>
);

const AlternativeShiftCard = ({ shift }) => (
  <div className="border border-gray-200 rounded-lg p-3 mb-3 bg-white hover:shadow-md transition-shadow">
    <div className="flex justify-between">
      <div>
        <h4 className="font-medium text-gray-900">{shift.jobTitle}</h4>
        <p className="text-sm text-gray-700">{shift.company}</p>
      </div>
      <div className="bg-green-100 text-green-800 h-fit text-xs font-medium px-2 py-1 rounded">
        {shift.match}
      </div>
    </div>
    
    <div className="grid grid-cols-2 gap-2 mt-2">
      <div>
        <p className="text-xs text-gray-500">Date & Time</p>
        <p className="text-sm">{shift.date}, {shift.time}</p>
      </div>
      <div>
        <p className="text-xs text-gray-500">Pay</p>
        <p className="text-sm font-medium">{shift.pay}</p>
      </div>
      <div>
        <p className="text-xs text-gray-500">Location</p>
        <p className="text-sm">{shift.distance}</p>
      </div>
    </div>
    
    <button className="w-full mt-3 px-3 py-2 bg-[#003A9B] text-white text-sm rounded hover:bg-[#0046bd]">
      Apply For This Shift
    </button>
  </div>
);

const ScheduleInsightCard = ({ icon, title, value, description }) => (
  <div className="bg-white rounded-lg border border-gray-200 p-4">
    <div className="flex items-center text-[#003A9B] mb-2">
      <FontAwesomeIcon icon={icon} className="mr-2" />
      <span className="text-sm font-medium text-gray-700">{title}</span>
    </div>
    <div className="text-xl font-bold text-gray-900">{value}</div>
    {description && (
      <p className="text-xs text-gray-600 mt-1">{description}</p>
    )}
  </div>
);

const PaymentInsightCard = ({ insight, onAction }) => {
  const getTypeIcon = (type) => {
    switch (type) {
      case 'discrepancy':
        return faExclamationCircle;
      case 'forecast':
        return faChartLine;
      case 'tip':
        return faStar;
      case 'instant':
        return faCoins;
      default:
        return faLightbulb;
    }
  };
  
  const getSeverityColor = (severity) => {
    switch (severity) {
      case 'high':
        return 'bg-red-50 border-red-200';
      case 'medium':
        return 'bg-orange-50 border-orange-200';
      default:
        return 'bg-blue-50 border-blue-200';
    }
  };
  
  const getIconColor = (severity) => {
    switch (severity) {
      case 'high':
        return 'text-red-500';
      case 'medium':
        return 'text-orange-500';
      default:
        return 'text-blue-500';
    }
  };
  
  return (
    <div className={`${getSeverityColor(insight.severity)} border rounded-lg p-4 mb-3 ${insight.isRead ? 'opacity-80' : ''}`}>
      <div className="flex items-start">
        <div className={`${getIconColor(insight.severity)} mr-3 mt-1`}>
          <FontAwesomeIcon icon={getTypeIcon(insight.type)} size="lg" />
        </div>
        <div className="flex-1">
          <h4 className="font-medium text-gray-900">{insight.title}</h4>
          <p className="text-sm text-gray-700 mt-1">{insight.message}</p>
          <div className="flex mt-3 space-x-2">
            {insight.actions.map((action, index) => (
              <button
                key={index}
                onClick={() => onAction(insight.id, action)}
                className={index === 0 
                  ? "px-3 py-1 bg-[#003A9B] text-white text-xs rounded hover:bg-[#0046bd]"
                  : "px-3 py-1 bg-gray-100 text-gray-700 text-xs rounded hover:bg-gray-200"
                }
              >
                {action}
              </button>
            ))}
          </div>
        </div>
        {!insight.isRead && (
          <div className="h-2 w-2 bg-blue-500 rounded-full"></div>
        )}
      </div>
    </div>
  );
};

const CurrentEarningsCard = ({ earnings }) => (
  <div className="bg-white rounded-lg shadow-sm p-6 border border-gray-100">
    <div className="flex justify-between items-start mb-5">
      <div>
        <h3 className="text-lg font-semibold text-gray-900">Current Period Earnings</h3>
        <p className="text-xs text-gray-500">{earnings.period} • Updated {earnings.lastUpdated}</p>
      </div>
      <div>
        <span className="bg-green-100 text-green-800 px-2 py-1 rounded text-xs font-medium">
          {earnings.shiftsCompleted}/{earnings.shiftsCompleted + earnings.shiftsRemaining} Shifts Completed
        </span>
      </div>
    </div>
    
    <div className="flex items-end justify-between mb-5">
      <div>
        <p className="text-sm text-gray-600">Current Earnings</p>
        <p className="text-3xl font-bold text-gray-900">{earnings.currentAmount}</p>
      </div>
      <div className="text-right">
        <p className="text-sm text-gray-600">Expected Total</p>
        <p className="text-2xl font-semibold text-gray-700">{earnings.expectedTotal}</p>
      </div>
    </div>
    
    <div className="h-2 bg-gray-200 rounded-full overflow-hidden mb-5">
      <div 
        className="h-full bg-[#003A9B] rounded-full" 
        style={{ width: `${(earnings.hoursWorked / earnings.hoursScheduled) * 100}%` }}
      ></div>
    </div>
    
    <div className="grid grid-cols-3 gap-3 mb-4">
      <div className="border border-gray-200 rounded p-2">
        <p className="text-xs text-gray-500">Hours Worked</p>
        <p className="font-medium">{earnings.hoursWorked} / {earnings.hoursScheduled} hrs</p>
      </div>
      <div className="border border-gray-200 rounded p-2">
        <p className="text-xs text-gray-500">Base Pay</p>
        <p className="font-medium">{earnings.breakdown.basePay}</p>
      </div>
      <div className="border border-gray-200 rounded p-2">
        <p className="text-xs text-gray-500">Tips + Overtime</p>
        <p className="font-medium">{earnings.breakdown.tips} + {earnings.breakdown.overtime}</p>
      </div>
    </div>
    
    <button className="w-full px-4 py-2 bg-[#003A9B] text-white rounded-md text-sm hover:bg-[#0046bd] transition-colors duration-300">
      View Full Earnings Breakdown
    </button>
  </div>
);

const EnhancedPaymentCard = ({ payment, showDetails = false }) => {
  const [expanded, setExpanded] = useState(showDetails);
  
  return (
    <div className="bg-white rounded-lg shadow-sm p-4 border border-gray-100">
      <div className="flex justify-between items-start">
        <div>
          <p className="text-gray-600 text-sm">{payment.date}</p>
          <h3 className="font-semibold text-gray-900">{payment.company}</h3>
        </div>
        <div className="flex flex-col items-end">
          <span className="bg-green-100 text-green-800 px-2 py-1 rounded-full text-xs font-medium mb-1">
            {payment.status}
          </span>
          <button 
            onClick={() => setExpanded(!expanded)}
            className="text-xs text-[#003A9B] underline"
          >
            {expanded ? 'Hide Details' : 'Show Details'}
          </button>
        </div>
      </div>
      
      <div className={`transition-all duration-300 overflow-hidden ${expanded ? 'mt-4 max-h-80' : 'max-h-0'}`}>
        <div className="border-t border-gray-200 pt-3">
          <div className="grid grid-cols-4 gap-3 mb-2">
            <div>
              <p className="text-xs text-gray-500">Hours</p>
              <p className="text-sm font-medium">{payment.hours} hrs</p>
            </div>
            <div>
              <p className="text-xs text-gray-500">Base Pay</p>
              <p className="text-sm font-medium">{payment.basePay}</p>
            </div>
            <div>
              <p className="text-xs text-gray-500">Tips</p>
              <p className="text-sm font-medium">{payment.tips}</p>
            </div>
            <div>
              <p className="text-xs text-gray-500">Overtime</p>
              <p className="text-sm font-medium">{payment.overtime}</p>
            </div>
          </div>
          
          <div className="flex justify-between items-center pt-2 border-t border-gray-100">
            <div>
              <p className="text-xs text-gray-500">Deductions</p>
              <p className="text-sm">{payment.deductions}</p>
            </div>
            <div>
              <p className="text-xs text-gray-500">Method</p>
              <p className="text-sm">{payment.method}</p>
            </div>
            <div>
              <p className="text-xs text-gray-500">Total Amount</p>
              <p className="text-sm font-bold text-gray-900">{payment.amount}</p>
            </div>
          </div>
        </div>
      </div>
      
      {!expanded && (
        <div className="flex justify-between mt-3">
          <div>
            <p className="text-xs text-gray-500">Hours</p>
            <p className="text-sm">{payment.hours} hrs</p>
          </div>
          <div className="text-right">
            <p className="text-xs text-gray-500">Amount</p>
            <p className="text-sm font-medium text-gray-900">{payment.amount}</p>
          </div>
        </div>
      )}
    </div>
  );
};

const ForecastCard = ({ forecast }) => (
  <div className="bg-white rounded-lg shadow-sm p-5 border border-gray-100">
    <h3 className="text-lg font-semibold text-gray-900 mb-4">Earnings Forecast</h3>
    
    <div className="flex justify-between items-center mb-6 border-b border-gray-100 pb-4">
      <div className="text-center">
        <p className="text-sm text-gray-600">{forecast.lastMonth.name}</p>
        <p className="font-medium">{forecast.lastMonth.amount}</p>
      </div>
      <div className="text-center">
        <p className="text-sm text-gray-600 font-medium">{forecast.currentMonth}</p>
        <p className="text-xl font-bold text-[#003A9B]">{forecast.projectedAmount}</p>
      </div>
      <div className="text-center">
        <p className="text-sm text-gray-600">{forecast.nextMonth.name} (Est.)</p>
        <p className="font-medium">{forecast.nextMonth.amount}</p>
        <p className="text-xs text-gray-500">{forecast.nextMonth.confidence} confidence</p>
      </div>
    </div>
    
    <div className="mb-5">
      <p className="text-sm font-medium text-gray-700 mb-2">Projected by Job</p>
      {forecast.projectedByJob.map((job, index) => (
        <div key={index} className="mb-2">
          <div className="flex justify-between text-sm mb-1">
            <span>{job.company}</span>
            <span className="font-medium">{job.amount}</span>
          </div>
          <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
            <div 
              className="h-full bg-blue-500 rounded-full" 
              style={{ width: `${job.percentage}%` }}
            ></div>
          </div>
        </div>
      ))}
    </div>
    
    <div className="bg-blue-50 border border-blue-100 rounded p-3">
      <div className="flex justify-between items-center mb-2">
        <p className="text-sm font-medium">Monthly Savings Goal</p>
        <p className="text-sm">{forecast.savingsGoal.progress} / {forecast.savingsGoal.target}</p>
      </div>
      <div className="h-2 bg-gray-200 rounded-full overflow-hidden mb-2">
        <div 
          className="h-full bg-green-500 rounded-full" 
          style={{ width: `${(parseInt(forecast.savingsGoal.progress.replace(/\$|,/g, '')) / parseInt(forecast.savingsGoal.target.replace(/\$|,/g, ''))) * 100}%` }}
        ></div>
      </div>
      <p className="text-xs text-gray-600">
        You're ${forecast.savingsGoal.shortfall.replace('$', '')} away from your monthly savings goal
      </p>
    </div>
  </div>
);

const InstantPayCard = ({ currentEarnings }) => (
  <div className="bg-gradient-to-r from-blue-600 to-indigo-700 rounded-lg shadow-lg p-5 text-white">
    <div className="flex justify-between items-start">
      <div>
        <h3 className="font-semibold text-lg mb-1">Instant Pay Available</h3>
        <p className="text-sm opacity-90">Access your earnings before payday</p>
      </div>
      <div className="bg-white bg-opacity-20 rounded-full p-2">
        <FontAwesomeIcon icon={faCoins} className="text-yellow-300" />
      </div>
    </div>
    
    <div className="mt-4 mb-5">
      <p className="text-sm">Current earnings available:</p>
      <p className="text-3xl font-bold">{currentEarnings.currentAmount}</p>
      <p className="text-xs opacity-80 mt-1">Up to 80% available for instant access</p>
    </div>
    
    <div className="bg-white bg-opacity-10 rounded-lg p-3 mb-4">
      <div className="flex justify-between mb-2">
        <span className="text-sm">Available for instant pay:</span>
        <span className="text-sm font-medium">$200.00</span>
      </div>
      <div className="flex justify-between mb-2">
        <span className="text-sm">Fee:</span>
        <span className="text-sm font-medium">$1.99</span>
      </div>
      <div className="border-t border-white border-opacity-20 pt-2 flex justify-between">
        <span className="text-sm font-medium">You'll receive:</span>
        <span className="text-sm font-medium">$198.01</span>
      </div>
    </div>
    
    <button className="w-full bg-white text-indigo-700 font-medium py-2 rounded-md hover:bg-blue-50 transition-colors duration-300">
      Get Paid Now
    </button>
  </div>
);

const UserDashboard = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState('jobs');
  const [isProcessing, setIsProcessing] = useState(false);
  const [searchResults, setSearchResults] = useState(null);
  const [showAISearchBar, setShowAISearchBar] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  
  // Schedule-related states
  const [notifications, setNotifications] = useState(AI_SCHEDULE_NOTIFICATIONS);
  const [scheduleConflict] = useState(SCHEDULE_CONFLICTS[0]);
  const [showConflictResolution, setShowConflictResolution] = useState(false);
  const [showAlternatives, setShowAlternatives] = useState(false);
  const [aiScheduleInsights] = useState(true);
  const [askingAI, setAskingAI] = useState('');
  const [aiResponse, setAiResponse] = useState(null);
  
  // Payment-related states
  const [paymentInsights, setPaymentInsights] = useState(PAYMENT_INSIGHTS);
  const [showForecast, setShowForecast] = useState(false);
  const [showInstantPay, setShowInstantPay] = useState(false); // Add this line
  const [currentEarnings] = useState(CURRENT_EARNINGS);
  const [forecast] = useState(EARNINGS_FORECAST);
  const [selectedPeriod, setSelectedPeriod] = useState('Last 12 Months');
  const [graphData, setGraphData] = useState(EARNINGS_DATA['Last 12 Months']);

  // Simulate AI processing for natural language queries
  const handleAISearch = (query) => {
    setSearchQuery(query);
    setIsProcessing(true);
    setShowSuggestions(false);
    
    // Simulate processing time
    setTimeout(() => {
      let filteredJobs = [...MOCK_JOBS];
      
      // Demo filtering logic based on common phrases
      if (query.toLowerCase().includes('near my area') || query.toLowerCase().includes('near me')) {
        filteredJobs = MOCK_JOBS.filter(job => job.distance.includes('miles'));
      } else if (query.toLowerCase().includes('part-time')) {
        filteredJobs = MOCK_JOBS.filter(job => job.type === 'Part-time');
      } else if (query.toLowerCase().includes('remote')) {
        filteredJobs = MOCK_JOBS.filter(job => job.location === 'Remote');
      } else if (query.toLowerCase().includes('weekend')) {
        filteredJobs = MOCK_JOBS.filter(job => job.schedule.toLowerCase().includes('weekend'));
      } else if (query.toLowerCase().includes('flexible')) {
        filteredJobs = MOCK_JOBS.filter(job => job.schedule.toLowerCase().includes('flexible'));
      }
      
      // Set results with explanation
      setSearchResults({
        query: query,
        explanation: `Here are ${filteredJobs.length} jobs matching your search criteria.`,
        jobs: filteredJobs
      });
      
      setIsProcessing(false);
    }, 1500);
  };

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  // Handle notification actions
  const handleNotificationAction = (id, action) => {
    // Mark notification as read
    setNotifications(prev => 
      prev.map(n => n.id === id ? {...n, isRead: true} : n)
    );
    
    // Handle specific actions
    if (id === 'notif1' && action === 'Resolve Conflict') {
      setShowConflictResolution(true);
    } else if (id === 'notif2' && action === 'View Options') {
      setShowAlternatives(true);
    }
  };
  
  // Handle payment insight actions
  const handlePaymentInsightAction = (id, action) => {
    // Mark insight as read
    setPaymentInsights(prev => 
      prev.map(p => p.id === id ? {...p, isRead: true} : p)
    );
    
    // Handle specific actions
    if (id === 'insight1' && action === 'Flag for Review') {
      // In a real app, this would send a request to review the discrepancy
      alert('Discrepancy has been flagged for review. HR will be notified.');
    } else if (id === 'insight2' && action === 'View Details') {
      setShowForecast(true);
    } else if (id === 'insight4' && action === 'Get Instant Pay') {
      setShowInstantPay(true);
    }
  };

  // Simulate AI response for schedule questions
  const handleScheduleAIQuestion = (question) => {
    setAskingAI(question);
    setAiResponse(null);
    
    // Simulate processing time
    setTimeout(() => {
      let response = '';
      
      if (question.toLowerCase().includes('schedule') && question.toLowerCase().includes('coffee shop')) {
        response = "You have 2 shifts at Local Coffee Shop: Monday (Apr 15) from 9AM-2PM and Wednesday (Apr 17) from 10AM-3PM. Both shifts are confirmed and will pay $18/hr.";
      } else if (question.toLowerCase().includes('work') && question.toLowerCase().includes('next week')) {
        response = "Based on your availability and preferences, I predict you'll have 5 shifts next week across your jobs, totaling approximately 25-30 hours. Your estimated earnings would be $540-650.";
      } else if (question.toLowerCase().includes('conflict')) {
        response = "You have one schedule conflict on Wednesday, April 17 between your Coffee Shop shift (10AM-3PM) and Fashion Outlet shift (12PM-5PM). I recommend keeping the Coffee Shop shift based on your preferences and finding a replacement for Fashion Outlet.";
      } else if (question.toLowerCase().includes('cancel')) {
        response = "No shifts have been canceled this week. If a shift gets canceled, I'll immediately notify you and suggest alternatives to maintain your income.";
      } else {
        response = "I'm sorry, I don't have specific information about that. Could you try asking about your upcoming shifts, potential conflicts, or weekly schedule?";
      }
      
      setAiResponse(response);
      setAskingAI('');
    }, 1500);
  };

  // Reset search results when tab changes
  useEffect(() => {
    setSearchResults(null);
    setSearchQuery('');
  }, [activeTab]);

  // Add this function after the existing useEffect
  useEffect(() => {
    setGraphData(EARNINGS_DATA[selectedPeriod]);
  }, [selectedPeriod]);

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header & Navigation */}
      <nav className="bg-[#003A9B] shadow-lg">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-16">
            <div className="flex-shrink-0">
              <span className="text-white text-lg font-bold">WorkFission</span>
            </div>

            <div className="hidden md:flex space-x-4">
              <button 
                className={`px-4 py-2 text-white rounded-md transition-colors duration-300 ${
                  activeTab === 'jobs' ? 'bg-[#0046bd]' : 'hover:bg-[#0046bd]'
                }`}
                onClick={() => setActiveTab('jobs')}
              >
                <FontAwesomeIcon icon={faBriefcase} className="mr-2" />
                Jobs
              </button>
              <button 
                className={`px-4 py-2 text-white rounded-md transition-colors duration-300 ${
                  activeTab === 'schedule' ? 'bg-[#0046bd]' : 'hover:bg-[#0046bd]'
                }`}
                onClick={() => setActiveTab('schedule')}
              >
                <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
                Schedule
              </button>
              <button 
                className={`px-4 py-2 text-white rounded-md transition-colors duration-300 ${
                  activeTab === 'pay' ? 'bg-[#0046bd]' : 'hover:bg-[#0046bd]'
                }`}
                onClick={() => setActiveTab('pay')}
              >
                <FontAwesomeIcon icon={faDollarSign} className="mr-2" />
                Pay
              </button>
            </div>

            <div>
          <button
            onClick={handleSignOut}
            className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
          >
            Sign Out
          </button>
        </div>
      </div>
        </div>
      </nav>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-gray-900">Job Seeker Dashboard</h1>
          <p className="text-gray-600 mt-2">Welcome to your job seeker dashboard!</p>
        </div>

        {/* Mobile Navigation */}
        <div className="md:hidden flex justify-between bg-white rounded-lg shadow-sm p-2 mb-6">
          <button 
            className={`flex-1 px-4 py-2 rounded-md text-sm ${
              activeTab === 'jobs' ? 'bg-[#003A9B] text-white' : 'text-gray-700'
            }`}
            onClick={() => setActiveTab('jobs')}
          >
            <FontAwesomeIcon icon={faBriefcase} className="mr-1" />
            Jobs
          </button>
          <button 
            className={`flex-1 px-4 py-2 rounded-md text-sm ${
              activeTab === 'schedule' ? 'bg-[#003A9B] text-white' : 'text-gray-700'
            }`}
            onClick={() => setActiveTab('schedule')}
          >
            <FontAwesomeIcon icon={faCalendarAlt} className="mr-1" />
            Schedule
          </button>
          <button 
            className={`flex-1 px-4 py-2 rounded-md text-sm ${
              activeTab === 'pay' ? 'bg-[#003A9B] text-white' : 'text-gray-700'
            }`}
            onClick={() => setActiveTab('pay')}
          >
            <FontAwesomeIcon icon={faDollarSign} className="mr-1" />
            Pay
          </button>
        </div>

        {/* Jobs Tab */}
        {activeTab === 'jobs' && (
          <>
            {/* AI Search Interface */}
            <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-xl font-semibold text-gray-900">Find Your Next Job</h2>
                <button 
                  onClick={() => setShowAISearchBar(!showAISearchBar)}
                  className="flex items-center bg-[#003A9B] text-white px-4 py-2 rounded-md text-sm hover:bg-[#0046bd] transition-colors duration-300"
                >
                  <FontAwesomeIcon icon={faMicrophone} className="mr-2" />
                  AI Search
                </button>
              </div>
              
              {/* Regular Search Bar */}
              {!showAISearchBar && (
                <div className="flex items-center border border-gray-300 rounded-md">
                  <div className="p-2 border-r border-gray-300">
                    <FontAwesomeIcon icon={faSearch} className="text-gray-400" />
                  </div>
                  <input
                    type="text"
                    placeholder="Search jobs by title, company, or location..."
                    className="w-full px-4 py-2 focus:outline-none"
                  />
                  <button className="bg-[#003A9B] text-white px-4 py-2 rounded-tr-md rounded-br-md text-sm hover:bg-[#0046bd] transition-colors duration-300">
                    Search
                  </button>
                </div>
              )}
              
              {/* AI Natural Language Search */}
              {showAISearchBar && (
                <div className="relative">
                  <div className="flex items-center border border-blue-300 rounded-md bg-blue-50">
                    <div className="p-2 border-r border-blue-300">
                      <FontAwesomeIcon icon={faMicrophone} className="text-[#003A9B]" />
                    </div>
                    <input
                      type="text"
                      placeholder="Ask in natural language, e.g., 'Part-time jobs near me'..."
                      className="w-full px-4 py-2 bg-blue-50 focus:outline-none"
                      value={searchQuery}
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                        setShowSuggestions(true);
                      }}
                      onFocus={() => setShowSuggestions(true)}
                    />
                    <button 
                      className="bg-[#003A9B] text-white px-4 py-2 rounded-tr-md rounded-br-md text-sm hover:bg-[#0046bd] transition-colors duration-300"
                      onClick={() => handleAISearch(searchQuery)}
                      disabled={isProcessing}
                    >
                      {isProcessing ? 'Processing...' : 'Ask AI'}
                    </button>
                  </div>
                  
                  {/* Search Suggestions */}
                  {showSuggestions && (
                    <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-md shadow-lg">
                      <div className="p-2 border-b border-gray-200">
                        <p className="text-xs text-gray-500">Try asking:</p>
                      </div>
                      {AI_SEARCH_SUGGESTIONS.map((suggestion, index) => (
                        <div 
                          key={index}
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => handleAISearch(suggestion)}
                        >
                          {suggestion}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
              
              {/* AI Search Results */}
              {isProcessing && (
                <div className="mt-4 text-center p-4">
                  <div className="animate-pulse flex space-x-4 justify-center">
                    <div className="h-3 w-3 bg-blue-400 rounded-full"></div>
                    <div className="h-3 w-3 bg-blue-400 rounded-full"></div>
                    <div className="h-3 w-3 bg-blue-400 rounded-full"></div>
                  </div>
                  <p className="text-gray-600 mt-2">Analyzing your query and finding the best matches...</p>
                </div>
              )}
              
              {searchResults && !isProcessing && (
                <div className="mt-4">
                  <div className="bg-blue-50 border border-blue-200 rounded-md p-4 mb-4">
                    <p className="text-sm text-gray-700">
                      <span className="font-medium">Search query:</span> "{searchResults.query}"
                    </p>
                    <p className="text-sm text-gray-700">
                      <span className="font-medium">AI response:</span> {searchResults.explanation}
                    </p>
                  </div>
                </div>
              )}
            </div>
            
            {/* Job Listings */}
            <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">
                {searchResults ? 'Search Results' : 'Recommended Jobs'}
              </h2>
              
              <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-1">
                {(searchResults ? searchResults.jobs : MOCK_JOBS).map(job => (
                  <JobCard key={job.id} job={job} />
                ))}
              </div>
              
              {/* Show message if no jobs found */}
              {searchResults && searchResults.jobs.length === 0 && (
                <div className="text-center py-6">
                  <p className="text-gray-600">No jobs match your search criteria. Try broadening your search.</p>
                </div>
              )}
            </div>
          </>
        )}

        {/* Schedule Tab */}
        {activeTab === 'schedule' && (
          <>
            {/* AI Schedule Assistant */}
            <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-xl font-semibold text-gray-900">AI Schedule Assistant</h2>
                <div className="text-sm text-gray-600 flex items-center">
                  <FontAwesomeIcon icon={faCheckCircle} className="text-green-500 mr-1" />
                  Active
                </div>
              </div>
              
              {/* Ask AI about schedule */}
              <div className="relative mb-6">
                <div className="flex items-center border border-blue-300 rounded-md bg-blue-50">
                  <div className="p-2 border-r border-blue-300">
                    <FontAwesomeIcon icon={faMicrophone} className="text-[#003A9B]" />
                  </div>
                  <input
                    type="text"
                    placeholder="Ask about your schedule, e.g., 'What's my Coffee Shop schedule?'"
                    className="w-full px-4 py-3 bg-blue-50 focus:outline-none"
                    value={askingAI}
                    onChange={(e) => setAskingAI(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' && askingAI.trim()) {
                        handleScheduleAIQuestion(askingAI);
                      }
                    }}
                  />
                  <button 
                    className="bg-[#003A9B] text-white px-4 py-2 rounded-tr-md rounded-br-md hover:bg-[#0046bd] transition-colors duration-300"
                    onClick={() => askingAI.trim() && handleScheduleAIQuestion(askingAI)}
                  >
                    Ask AI
                  </button>
                </div>
                
                {/* Example questions */}
                <div className="flex flex-wrap mt-2 gap-2">
                  {[
                    "What's my Coffee Shop schedule?",
                    "How many hours will I work next week?",
                    "Do I have any schedule conflicts?",
                    "Any canceled shifts this week?"
                  ].map((q, i) => (
                    <button
                      key={i}
                      className="text-xs bg-gray-100 text-gray-800 px-2 py-1 rounded hover:bg-gray-200"
                      onClick={() => handleScheduleAIQuestion(q)}
                    >
                      {q}
                    </button>
                  ))}
                </div>
              </div>
              
              {/* AI Response */}
              {aiResponse && (
                <div className="bg-blue-50 border border-blue-200 rounded-md p-4 mb-4">
                  <div className="flex items-start">
                    <div className="text-blue-600 mr-3">
                      <FontAwesomeIcon icon={faLightbulb} />
                    </div>
                    <div>
                      <p className="text-gray-900">{aiResponse}</p>
                    </div>
                  </div>
                </div>
              )}
              
              {/* AI-detected issues */}
              <div>
                <h3 className="text-sm font-semibold text-gray-700 mb-2 flex items-center">
                  <FontAwesomeIcon icon={faExclamationCircle} className="text-[#003A9B] mr-2" />
                  AI-Detected Schedule Issues
                </h3>
                
                {notifications.length > 0 ? (
                  <div className="space-y-3">
                    {notifications.map(notification => (
                      <NotificationCard 
                        key={notification.id} 
                        notification={notification}
                        onAction={handleNotificationAction}
                      />
                    ))}
                  </div>
                ) : (
                  <p className="text-center py-4 text-gray-500">No issues detected with your current schedule.</p>
                )}
              </div>
            </div>
            
            {/* Conflict Resolution (shows conditionally) */}
            {showConflictResolution && (
              <ConflictResolutionCard conflict={scheduleConflict} />
            )}
            
            {/* Alternative Shifts (shows conditionally) */}
            {showAlternatives && (
              <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
                <h3 className="text-lg font-semibold text-gray-900 mb-3">Recommended Alternative Shifts</h3>
                <p className="text-sm text-gray-600 mb-4">
                  Based on your skills, location, and availability, here are shifts that could replace your canceled shift:
                </p>
                
                <div className="space-y-3">
                  {ALTERNATIVE_SHIFTS.map(shift => (
                    <AlternativeShiftCard key={shift.id} shift={shift} />
                  ))}
                </div>
              </div>
            )}
            
            {/* AI Schedule Insights */}
            {aiScheduleInsights && (
              <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-lg font-semibold text-gray-900">AI Schedule Insights</h3>
                  <button className="text-sm text-[#003A9B]">Refresh</button>
                </div>
                
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
                  <ScheduleInsightCard 
                    icon={faCalendarCheck}
                    title="Shifts This Week"
                    value={SCHEDULE_INSIGHTS.shifts}
                    description="4 shifts across 2 employers"
                  />
                  <ScheduleInsightCard 
                    icon={faClock}
                    title="Total Hours"
                    value={`${SCHEDULE_INSIGHTS.hoursWorked} hrs`}
                    description={`${SCHEDULE_INSIGHTS.recommendedHours} hrs recommended`}
                  />
                  <ScheduleInsightCard 
                    icon={faCoins}
                    title="Expected Pay"
                    value={SCHEDULE_INSIGHTS.expectedPay}
                    description="Before tips and bonuses"
                  />
                  <ScheduleInsightCard 
                    icon={faFire}
                    title="Rest Periods"
                    value={SCHEDULE_INSIGHTS.restPeriods}
                    description={`Burnout risk: ${SCHEDULE_INSIGHTS.burnoutRisk}`}
                  />
                </div>
                
                <div className="border border-blue-100 bg-blue-50 rounded-md p-4">
                  <h4 className="text-sm font-semibold text-gray-900 mb-2">AI-Generated Insights:</h4>
                  <ul className="space-y-2 text-sm text-gray-700">
                    <li className="flex items-start">
                      <FontAwesomeIcon icon={faChartLine} className="text-blue-500 mt-1 mr-2" />
                      Your schedule consistency is {SCHEDULE_INSIGHTS.consistency.toLowerCase()}. More consistent shifts could help stabilize your income.
                    </li>
                    <li className="flex items-start">
                      <FontAwesomeIcon icon={faExchange} className="text-blue-500 mt-1 mr-2" />
                      Based on your preferences, trading your Friday evening shift for a Saturday morning shift could increase your earnings by ~$25.
                    </li>
                    <li className="flex items-start">
                      <FontAwesomeIcon icon={faFire} className="text-blue-500 mt-1 mr-2" />
                      Your current schedule projects to {SCHEDULE_INSIGHTS.incomeProjection} if maintained, which is 15% higher than last month.
                    </li>
                  </ul>
                </div>
              </div>
            )}
            
            {/* Upcoming Shifts */}
            <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Upcoming Shifts</h2>
              
              <div className="space-y-4">
                {MOCK_UPCOMING_SHIFTS.map(shift => (
                  <ShiftCard key={shift.id} shift={shift} />
                ))}
              </div>
              
              {MOCK_UPCOMING_SHIFTS.length === 0 && (
                <div className="text-center py-6">
                  <p className="text-gray-600">You don't have any upcoming shifts scheduled.</p>
                </div>
              )}
            </div>
            
            {/* Weekly Schedule Calendar */}
            <div className="bg-white rounded-lg shadow-sm p-6">
              <h3 className="text-lg font-semibold text-gray-900 mb-3">Weekly Schedule</h3>
              
              <div className="grid grid-cols-7 gap-2 mb-4">
                {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
                  <div key={day} className="text-center font-medium text-gray-700">
                    {day}
                  </div>
                ))}
                
                {/* Mock calendar days */}
                {Array(7).fill(0).map((_, i) => (
                  <div 
                    key={i} 
                    className={`h-28 border border-gray-200 rounded-md p-1 relative ${
                      i === 1 || i === 3 ? 'bg-blue-50 border-blue-200' : 
                      i === 5 ? 'bg-yellow-50 border-yellow-200' : ''
                    }`}
                  >
                    <div className="text-right text-xs text-gray-500">{i + 14}</div>
                    {i === 1 && (
                      <div className="mt-1 bg-blue-100 border border-blue-300 rounded p-1 text-xs">
                        <p className="font-medium">Coffee Shop</p>
                        <p>9AM - 2PM</p>
                      </div>
                    )}
                    {i === 3 && (
                      <div className="mt-1 bg-blue-100 border border-blue-300 rounded p-1 text-xs relative">
                        <p className="font-medium">Coffee Shop</p>
                        <p>10AM - 3PM</p>
                        
                        {/* Conflict indicator */}
                        <div className="absolute -top-1 -right-1 h-4 w-4 bg-red-500 rounded-full flex items-center justify-center">
                          <span className="text-white text-xs">!</span>
                        </div>
                      </div>
                    )}
                    {i === 3 && (
                      <div className="mt-1 bg-red-100 border border-red-300 rounded p-1 text-xs">
                        <p className="font-medium">Fashion Outlet</p>
                        <p>12PM - 5PM</p>
                        <span className="text-red-700 text-xs">Conflict</span>
                      </div>
                    )}
                    {i === 5 && (
                      <div className="mt-1 bg-yellow-100 border border-yellow-300 rounded p-1 text-xs">
                        <p className="font-medium">FastFood Express</p>
                        <p>5PM - 10PM</p>
                        <span className="text-yellow-700 text-xs">Pending</span>
                      </div>
                    )}
                    
                    {/* AI-recommended shift indicator */}
                    {i === 6 && (
                      <div className="absolute inset-0 flex items-center justify-center border-2 border-dashed border-blue-300 rounded-md">
                        <div className="text-center">
                          <div className="text-blue-500 mb-1">
                            <FontAwesomeIcon icon={faLightbulb} />
                          </div>
                          <span className="text-xs text-blue-700">AI Recommended Shift</span>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              
              <div className="flex justify-between">
                <button className="bg-gray-100 text-gray-700 px-4 py-2 rounded-md text-sm hover:bg-gray-200 transition-colors duration-300">
                  Previous Week
                </button>
                <button className="bg-[#003A9B] text-white px-4 py-2 rounded-md text-sm hover:bg-[#0046bd] transition-colors duration-300">
                  Next Week
                </button>
              </div>
            </div>
          </>
        )}

        {/* Pay Tab */}
        {activeTab === 'pay' && (
          <>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-6">
              {/* Current Period Earnings */}
              <div className="lg:col-span-2">
                <CurrentEarningsCard earnings={currentEarnings} />
              </div>
              
              {/* Instant Pay */}
              <div>
                <InstantPayCard currentEarnings={currentEarnings} />
              </div>
            </div>
            
            {/* AI Payment Insights */}
            <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold text-gray-900">AI Payment Insights</h2>
                <div className="flex items-center text-sm text-gray-600">
                  <FontAwesomeIcon icon={faLightbulb} className="text-yellow-500 mr-2" />
                  Powered by AI
                </div>
              </div>
              
              <div className="space-y-4">
                {paymentInsights.length > 0 ? (
                  paymentInsights.map(insight => (
                    <PaymentInsightCard 
                      key={insight.id} 
                      insight={insight} 
                      onAction={handlePaymentInsightAction}
                    />
                  ))
                ) : (
                  <p className="text-center py-4 text-gray-500">No new payment insights available.</p>
                )}
              </div>
            </div>
            
            {/* Earnings Forecast (conditionally shown) */}
            {showForecast && (
              <div className="mb-6">
                <ForecastCard forecast={forecast} />
              </div>
            )}
            
            {/* Monthly Earnings Visual */}
            <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold text-gray-900">Monthly Earnings</h2>
                <div className="flex space-x-2">
                  <select 
                    className="text-sm border border-gray-300 rounded px-2 py-1"
                    value={selectedPeriod}
                    onChange={(e) => setSelectedPeriod(e.target.value)}
                  >
                    <option>Last 12 Months</option>
                    <option>Last 6 Months</option>
                    <option>This Year</option>
                  </select>
                </div>
              </div>
              
              {/* Fixed Height Bar Graph */}
              <div className="relative h-64 bg-white rounded-lg mb-6">
                {/* Y-axis labels */}
                <div className="absolute left-0 top-0 bottom-0 w-16 flex flex-col justify-between">
                  <span className="text-xs text-gray-500">$3000</span>
                  <span className="text-xs text-gray-500">$2000</span>
                  <span className="text-xs text-gray-500">$1000</span>
                  <span className="text-xs text-gray-500">$0</span>
                </div>
                
                {/* Horizontal grid lines */}
                <div className="absolute left-16 right-0 h-full pointer-events-none">
                  <div className="absolute left-0 right-0 top-0 border-b border-gray-200"></div>
                  <div className="absolute left-0 right-0 top-1/3 border-b border-gray-200"></div>
                  <div className="absolute left-0 right-0 top-2/3 border-b border-gray-200"></div>
                  <div className="absolute left-0 right-0 bottom-0 border-b border-gray-200"></div>
                </div>
                
                {/* Bar container - Explicit heights */}
                <div className="absolute left-16 right-0 bottom-0 top-0 flex justify-between items-end">
                  {graphData.map((data, index) => {
                    // Calculate height as percentage of container
                    const barHeight = Math.max((data.earnings / 3000) * 180, 10);
                    return (
                      <div key={index} className="flex-1 flex justify-center items-end pb-5">
                        {/* Actual bar with fixed pixel height */}
                        <div
                          className={`relative w-12 ${
                            data.earnings === Math.max(...graphData.map(d => d.earnings))
                              ? 'bg-blue-700'
                              : 'bg-blue-400'
                          } rounded-t-sm hover:bg-blue-500 transition`}
                          style={{
                            height: `${barHeight}px`
                          }}
                        >
                          {/* Tooltip on hover */}
                          <div className="absolute opacity-0 hover:opacity-100 w-full -top-6 left-0 text-center">
                            <div className="bg-gray-800 text-white text-xs py-1 px-2 rounded">
                              ${data.earnings}
                            </div>
                          </div>
      </div>
                      </div>
                    );
                  })}
                </div>
                
                {/* X-axis labels - Fixed at bottom */}
                <div className="absolute left-16 right-0 bottom-0 flex justify-between">
                  {graphData.map((data, index) => (
                    <div key={index} className="flex-1 text-center">
                      <span className="text-xs text-gray-500">{data.month}</span>
                    </div>
                  ))}
                </div>
              </div>
              
              {/* AI Graph Insight */}
              <div className="bg-blue-50 border border-blue-100 rounded-md p-3">
                <div className="flex items-start">
                  <div className="text-blue-600 mr-3">
                    <FontAwesomeIcon icon={faLightbulb} />
                  </div>
                  <div>
                    <p className="text-sm text-gray-700">
                      <span className="font-medium">AI Insight:</span> {
                        selectedPeriod === 'Last 12 Months' 
                          ? "April was your highest earning month at $2,100. This coincides with taking 15% more weekend shifts compared to other months."
                          : selectedPeriod === 'Last 6 Months'
                          ? "November showed the strongest performance at $2,200, driven by holiday season opportunities and overtime shifts."
                          : "Q4 has been your most profitable quarter this year at $2,900, showing a 47% increase from Q3."
                      }
                    </p>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Payment History */}
            <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Payment History</h2>
              
              <div className="space-y-4">
                {MOCK_PAYMENT_HISTORY.map(payment => (
                  <EnhancedPaymentCard key={payment.id} payment={payment} />
                ))}
              </div>
              
              <button className="mt-6 bg-[#003A9B] text-white px-4 py-2 rounded-md text-sm hover:bg-[#0046bd] transition-colors duration-300">
                View Full Payment History
              </button>
            </div>
          </>
        )}
      </main>
    </div>
  );
};

export default UserDashboard; 