import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase/firebaseConfig';
import { db } from '../../firebase/firebaseConfig';
import { doc, getDoc, setDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faUser, 
  faCaretDown, 
  faCircleCheck, 
  faCircleXmark,
  faLightbulb,
  faBuilding,
  faUsers,
  faMoneyBillWave,
  faChartLine,
  faCalendar,
  faTimes,
  faRobot,
  faExchangeAlt,
  faBell,
  faMagic,
  faListAlt,
  faChevronLeft,
  faChevronRight,
  faBriefcase,
  faDollarSign,
  faChevronUp,
  faChevronDown
} from '@fortawesome/free-solid-svg-icons';

const NavLink = ({ href, children, isActive, onClick }) => (
  <a
    href={href}
    onClick={onClick}
    className={`inline-flex items-center px-4 py-2 border-b-2 font-medium text-sm ${
      isActive
        ? 'border-[#003A9B] text-[#003A9B]'
        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
    }`}
  >
    {children}
  </a>
);

const ValidationMessage = ({ message, type = 'error' }) => (
  <div className={`text-xs ${type === 'error' ? 'text-red-600' : 'text-green-600'} mt-1`}>
    <FontAwesomeIcon 
      icon={type === 'error' ? faCircleXmark : faCircleCheck} 
      className="mr-1"
    />
    {message}
  </div>
);

const ProfileDropdown = ({ isOpen, employerData, onSave, onSignOut, isLoading, error }) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [nameError, setNameError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [saveMessage, setSaveMessage] = useState({ text: '', type: '' });

  useEffect(() => {
    console.log('=== ProfileDropdown State Update ===');
    console.log('Props:', {
      isOpen,
      isLoading,
      hasData: !!employerData,
      error
    });
    console.log('Local State:', {
      name,
      phone,
      isSaving,
      nameError,
      phoneError
    });

    if (employerData && !isLoading) {
      console.log('Updating name and phone from employerData');
      setName(employerData.name || '');
      setPhone(employerData.phone || '');
    }
  }, [isOpen, isLoading, employerData, error, name, phone, isSaving, nameError, phoneError]);

  const validateName = (value) => {
    if (!value) return 'Name is required';
    if (value.length < 2) return 'Name must be at least 2 characters';
    if (value.length > 50) return 'Name must be less than 50 characters';
    if (!/^[a-zA-Z\s]*$/.test(value)) return 'Name can only contain letters and spaces';
    return '';
  };

  const validatePhone = (value) => {
    if (!value) return 'Phone number is required';
    // Accepts formats: +1-XXX-XXX-XXXX or +X-XXX-XXX-XXXX
    if (!/^\+\d{1,3}-\d{3}-\d{3}-\d{4}$/.test(value)) {
      return 'Invalid format. Use: +1-XXX-XXX-XXXX';
    }
    return '';
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    setName(value);
    setNameError(validateName(value));
    setSaveMessage({ text: '', type: '' });
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    // Auto-format phone number as user types
    let formatted = value.replace(/[^\d+]/g, '');
    if (formatted.startsWith('+')) {
      const parts = [
        formatted.slice(0, formatted.startsWith('+1') ? 2 : 3),
        formatted.slice(formatted.startsWith('+1') ? 2 : 3, formatted.startsWith('+1') ? 5 : 6),
        formatted.slice(formatted.startsWith('+1') ? 5 : 6, formatted.startsWith('+1') ? 8 : 9),
        formatted.slice(formatted.startsWith('+1') ? 8 : 9, formatted.startsWith('+1') ? 12 : 13)
      ];
      formatted = parts.filter(Boolean).join('-');
    }
    setPhone(formatted);
    setPhoneError(validatePhone(formatted));
    setSaveMessage({ text: '', type: '' });
  };

  const handleSave = async () => {
    const nameValidation = validateName(name);
    const phoneValidation = validatePhone(phone);

    setNameError(nameValidation);
    setPhoneError(phoneValidation);

    if (nameValidation || phoneValidation) {
      setSaveMessage({ text: 'Please fix the errors before saving', type: 'error' });
      return;
    }

    setIsSaving(true);
    setSaveMessage({ text: '', type: '' });

    try {
      await onSave({ name, phone });
      setSaveMessage({ text: 'Profile updated successfully!', type: 'success' });
      setTimeout(() => setSaveMessage({ text: '', type: '' }), 3000);
    } catch (error) {
      setSaveMessage({ text: 'Failed to save changes. Please try again.', type: 'error' });
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    console.log('ProfileDropdown rendered:', { isOpen, isLoading, hasData: !!employerData });
  }, [isOpen, isLoading, employerData]);

  if (!isOpen) return null;

  if (error) {
    return (
      <div className="w-80 bg-white rounded-md shadow-lg py-1">
        <div className="px-4 py-3 text-red-600">
          <p>Error loading profile data. Please try again.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="w-80 bg-white rounded-md shadow-lg py-1">
      <div className="px-4 py-3 border-b border-gray-200">
        <div className="flex items-center mb-4">
          <div className="bg-[#003A9B] rounded-full p-2 mr-3">
            <FontAwesomeIcon icon={faUser} className="text-white text-xl" />
          </div>
          <div>
            <div className="text-sm font-medium text-gray-900">
              {isLoading ? 'Loading...' : employerData?.name || 'No name set'}
            </div>
            <div className="text-xs text-gray-500">
              {isLoading ? 'Loading...' : employerData?.email || 'No email set'}
            </div>
          </div>
        </div>

        <div className="text-xs text-gray-500 mb-1">Employer ID</div>
        <div className="text-sm text-gray-700 font-medium mb-4">
          {isLoading ? 'Loading...' : employerData?.employerId || 'ID not generated'}
        </div>
        
        <div className="text-xs text-gray-500 mb-1">Name</div>
        <input
          type="text"
          value={name}
          onChange={handleNameChange}
          className={`w-full px-3 py-2 border ${
            nameError ? 'border-red-300' : 'border-gray-300'
          } rounded-md text-sm mb-1 focus:outline-none focus:ring-1 focus:ring-[#003A9B] focus:border-[#003A9B]`}
          placeholder="Enter your name"
        />
        {nameError && <ValidationMessage message={nameError} />}
        
        <div className="text-xs text-gray-500 mt-3 mb-1">Phone Number</div>
        <input
          type="tel"
          value={phone}
          onChange={handlePhoneChange}
          className={`w-full px-3 py-2 border ${
            phoneError ? 'border-red-300' : 'border-gray-300'
          } rounded-md text-sm mb-1 focus:outline-none focus:ring-1 focus:ring-[#003A9B] focus:border-[#003A9B]`}
          placeholder="+1-XXX-XXX-XXXX"
        />
        {phoneError && <ValidationMessage message={phoneError} />}
        
        {saveMessage.text && (
          <ValidationMessage 
            message={saveMessage.text} 
            type={saveMessage.type}
          />
        )}
        
        <button
          onClick={handleSave}
          disabled={isSaving || !!nameError || !!phoneError}
          className={`w-full mt-4 px-4 py-2 rounded-md transition-colors duration-300 ${
            isSaving || !!nameError || !!phoneError
              ? 'bg-gray-300 cursor-not-allowed'
              : 'bg-[#003A9B] text-white hover:bg-[#0046bd]'
          }`}
        >
          {isSaving ? 'Saving...' : 'Save Changes'}
        </button>
      </div>
      
      <div className="px-4 py-2">
        <button
          onClick={onSignOut}
          className="w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-red-50 rounded-md transition-colors duration-300"
        >
          Sign Out
        </button>
      </div>
    </div>
  );
};

const generateEmployerId = () => {
  const randomNum = Math.floor(10000000 + Math.random() * 90000000);
  return `WF-${randomNum}`;
};

const checkEmployerIdExists = async (employerId) => {
  try {
    if (!employerId || typeof employerId !== 'string') {
      throw new Error('Invalid employer ID format');
    }

    const employersRef = collection(db, 'employers');
    const q = query(employersRef, where('employerId', '==', employerId));
    
    // Add timeout for query
    const queryPromise = getDocs(q);
    const timeoutPromise = new Promise((_, reject) => 
      setTimeout(() => reject(new Error('Query timeout')), 5000)
    );
    
    const querySnapshot = await Promise.race([queryPromise, timeoutPromise]);
    return !querySnapshot.empty;
  } catch (error) {
    console.error('Error checking employer ID:', error);
    throw error;
  }
};

const generateUniqueEmployerId = async () => {
  try {
    let employerId;
    let exists = true;
    let attempts = 0;
    const maxAttempts = 5;

    while (exists && attempts < maxAttempts) {
      employerId = generateEmployerId();
      try {
        exists = await checkEmployerIdExists(employerId);
      } catch (error) {
        console.error(`Attempt ${attempts + 1} failed:`, error);
        if (attempts >= maxAttempts - 1) {
          throw error;
        }
      }
      attempts++;
    }

    if (attempts >= maxAttempts) {
      throw new Error('Failed to generate unique employer ID after multiple attempts');
    }

    if (!employerId || !employerId.startsWith('WF-')) {
      throw new Error('Generated ID is invalid');
    }

    return employerId;
  } catch (error) {
    console.error('Error generating unique ID:', error);
    throw error;
  }
};

// Custom Date Range Modal Component
const DateRangeModal = ({ isOpen, onClose, onApply }) => {
  const [startDate, setStartDate] = useState('2023-05-01');
  const [endDate, setEndDate] = useState('2023-05-15');

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-96">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold text-gray-900">Select Date Range</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">Start Date</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-1 focus:ring-[#003A9B] focus:border-[#003A9B]"
          />
        </div>
        
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-1">End Date</label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-1 focus:ring-[#003A9B] focus:border-[#003A9B]"
          />
        </div>
        
        <div className="flex justify-end space-x-2">
          <button
            onClick={onClose}
            className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              onApply(startDate, endDate);
              onClose();
            }}
            className="px-4 py-2 bg-[#003A9B] border border-transparent rounded-md text-sm font-medium text-white hover:bg-[#0046bd]"
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

// Shift Modal Component for adding/editing shifts
const ShiftModal = ({ isOpen, shift, onClose, onSave, mockScheduleData, days, dayLabels }) => {
  const [startTime, setStartTime] = useState(shift?.startTime || '9:00 AM');
  const [endTime, setEndTime] = useState(shift?.endTime || '5:00 PM');
  const employee = mockScheduleData?.employees.find(e => e.id === shift?.employeeId);
  const dayIndex = shift?.day ? days.indexOf(shift.day) : 0;
  const dayLabel = dayLabels[dayIndex];

  if (!isOpen || !shift) return null;

  const timeOptions = [
    '12:00 AM', '1:00 AM', '2:00 AM', '3:00 AM', '4:00 AM', '5:00 AM',
    '6:00 AM', '7:00 AM', '8:00 AM', '9:00 AM', '10:00 AM', '11:00 AM',
    '12:00 PM', '1:00 PM', '2:00 PM', '3:00 PM', '4:00 PM', '5:00 PM',
    '6:00 PM', '7:00 PM', '8:00 PM', '9:00 PM', '10:00 PM', '11:00 PM'
  ];

  const handleSave = () => {
    const updatedShift = {
      ...shift,
      startTime,
      endTime
    };
    onSave(updatedShift);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-96">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold text-gray-900">
            {shift.id ? 'Edit Shift' : 'Add New Shift'}
          </h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        
        <div className="mb-4">
          <div className="text-sm font-medium text-gray-700 mb-1">Employee</div>
          <div className="text-sm bg-gray-100 p-2 rounded">
            {employee ? employee.name : 'Unknown Employee'}
          </div>
        </div>
        
        <div className="mb-4">
          <div className="text-sm font-medium text-gray-700 mb-1">Day</div>
          <div className="text-sm bg-gray-100 p-2 rounded">
            {dayLabel}
          </div>
        </div>
        
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">Start Time</label>
          <select
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-1 focus:ring-[#003A9B] focus:border-[#003A9B]"
          >
            {timeOptions.map(time => (
              <option key={`start-${time}`} value={time}>{time}</option>
            ))}
          </select>
        </div>
        
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-1">End Time</label>
          <select
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
            className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-1 focus:ring-[#003A9B] focus:border-[#003A9B]"
          >
            {timeOptions.map(time => (
              <option key={`end-${time}`} value={time}>{time}</option>
            ))}
          </select>
        </div>
        
        <div className="flex justify-end space-x-2">
          <button
            onClick={onClose}
            className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-[#003A9B] border border-transparent rounded-md text-sm font-medium text-white hover:bg-[#0046bd]"
          >
            Save Shift
          </button>
        </div>
      </div>
    </div>
  );
};

const EmployerDashboard = () => {
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState('business');
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [employerData, setEmployerData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTimeRange, setSelectedTimeRange] = useState('Today');
  const [isDateRangeModalOpen, setIsDateRangeModalOpen] = useState(false);
  const [customDateRange, setCustomDateRange] = useState({ startDate: '', endDate: '' });
  
  // Scheduling state variables
  const [selectedBusiness, setSelectedBusiness] = useState('all');
  const [isShiftModalOpen, setIsShiftModalOpen] = useState(false);
  const [currentShift, setCurrentShift] = useState(null);
  const [currentCell, setCurrentCell] = useState({ employeeId: null, day: null });
  const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
  const dayLabels = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  
  // AI Chat state variables
  const [chatMessages, setChatMessages] = useState([
    { sender: 'ai', text: 'How can I help with your scheduling today?' },
    { sender: 'user', text: 'Are all shifts covered for next week at Cafe Sunshine?' },
    { sender: 'ai', text: 'Based on the current schedule, Cafe Sunshine has 2 uncovered shifts for next week: Tuesday evening (6PM-10PM) and Saturday morning (7AM-11AM). Would you like me to suggest available employees who could cover these shifts?' },
    { sender: 'user', text: 'Do I need to replace anyone who\'s on vacation soon?' },
    { sender: 'ai', text: 'Yes, Alice Johnson has requested vacation from June 10-15. You\'ll need coverage for 3 morning shifts at Cafe Sunshine. Bob Smith is available and has similar qualifications. Would you like me to automatically reassign these shifts to Bob?' }
  ]);
  const [newMessage, setNewMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  
  // Job Posting & Hiring state variables
  const [jobCreationMode, setJobCreationMode] = useState(false);
  const [jobFormData, setJobFormData] = useState({
    title: '',
    business: '',
    location: '',
    payRate: '',
    jobType: 'Full-time',
    shiftSchedule: '',
    benefits: '',
    description: '',
    requirements: ''
  });
  const [aiJobPrompt, setAiJobPrompt] = useState('');
  const [isProcessingJobAI, setIsProcessingJobAI] = useState(false);
  const [selectedJobTab, setSelectedJobTab] = useState('posting');
  const [selectedCandidateFilter, setSelectedCandidateFilter] = useState('all');
  const [jobChatMessages, setJobChatMessages] = useState([
    { sender: 'ai', text: 'How can I help you create a job posting today?' }
  ]);
  const [jobChatInput, setJobChatInput] = useState('');
  
  // AI responses for common scheduling questions
  const aiResponses = {
    'overtime': 'Currently, 3 employees are working overtime: Bob Smith (4 hours), Sarah Johnson (2 hours), and David Kim (6 hours). Would you like me to suggest schedule adjustments to reduce overtime?',
    'conflicts': 'I detected 2 scheduling conflicts next week: Carol Martinez is scheduled at two locations on Monday, and Frank Wilson has overlapping shifts on Thursday. Should I resolve these conflicts automatically?',
    'optimize': 'After analyzing your current schedule, I recommend the following optimizations: 1) Move Bob\'s Thursday shift to Wednesday, 2) Reduce morning staff on Monday by 1 person, 3) Add 1 person to Friday evening. These changes would save approximately $340 in labor costs. Apply these changes?',
    'most_shifts': 'This month, Alice Johnson has the most shifts (18), followed by Carol Martinez (16) and David Kim (15). Alice is approaching overtime hours. Would you like to redistribute some of her shifts?',
    'uncovered': 'I found 3 uncovered shifts next week: Tuesday evening (6PM-10PM) at Cafe Sunshine, Saturday morning (7AM-11AM) at Cafe Sunshine, and Thursday afternoon (2PM-6PM) at Urban Tech Solutions. Would you like me to suggest employees to cover these shifts?',
    'vacation': 'Currently, two employees have upcoming vacation: Alice Johnson (June 10-15, Cafe Sunshine) and David Kim (June 22-30, Urban Tech Solutions). I\'ve prepared coverage recommendations for both periods based on employee qualifications and availability.',
    'employees_available': 'For the Thursday evening shift at Cafe Sunshine, the following employees are available: 1) Bob Smith (preferred, has similar qualifications), 2) Eva Brown (available but requires training), 3) Frank Wilson (qualified but already at 38 hours this week). Who would you like to assign?',
    'shift_distribution': 'The current shift distribution analysis shows: Morning shifts (32%), Afternoon shifts (45%), Evening shifts (23%). This creates uneven coverage with afternoon overstaffed by approximately 15% compared to customer traffic patterns. Should I suggest a more balanced distribution?',
    'peak_hours': 'Based on your sales data, your peak hours are: Monday 12PM-2PM, Friday 5PM-8PM, and Saturday 11AM-2PM. Your current staffing only partially aligns with these peaks. Would you like me to suggest staffing adjustments to better match customer demand?',
    'employee_performance': 'Your highest performing employees this month are: Bob Smith (customer satisfaction score: 9.8/10), Carol Martinez (avg sales: $350/shift), and Eva Brown (efficiency rating: 95%). Consider offering incentives to retain these top performers.'
  };

  // Mock data for different time ranges
  const mockData = {
    'Today': {
      totalBusinesses: 3,
      totalEmployees: 24,
      revenue: '$3,450',
      payroll: '$1,280',
      netProfit: '$2,170',
      businesses: [
        {
          name: 'Downtown Café',
          location: '123 Main St, San Francisco',
          status: 'Active',
          revenue: '$1,250',
          payroll: '$480',
          employees: 8,
          insight: 'Consider reducing overlap in afternoon shifts to optimize payroll.'
        },
        {
          name: 'Uptown Restaurant',
          location: '456 Oak St, San Francisco',
          status: 'Active',
          revenue: '$1,800',
          payroll: '$520',
          employees: 10,
          insight: 'Revenue is up 15% from last week. Weekend staff performed exceptionally well.'
        },
        {
          name: 'Lakeside Bar',
          location: '789 Lake Ave, San Francisco',
          status: 'Active',
          revenue: '$400',
          payroll: '$280',
          employees: 6,
          insight: '20% higher payroll-to-revenue ratio. Consider merging two low-performing shifts.'
        }
      ]
    },
    'This Week': {
      totalBusinesses: 3,
      totalEmployees: 26,
      revenue: '$24,350',
      payroll: '$8,920',
      netProfit: '$15,430',
      businesses: [
        {
          name: 'Downtown Café',
          location: '123 Main St, San Francisco',
          status: 'Active',
          revenue: '$8,250',
          payroll: '$3,180',
          employees: 8,
          insight: 'Weekend brunch has been your highest performing shift this week.'
        },
        {
          name: 'Uptown Restaurant',
          location: '456 Oak St, San Francisco',
          status: 'Active',
          revenue: '$12,300',
          payroll: '$3,820',
          employees: 11,
          insight: 'Added staff on Friday boosted revenue by 22% compared to last week.'
        },
        {
          name: 'Lakeside Bar',
          location: '789 Lake Ave, San Francisco',
          status: 'Active',
          revenue: '$3,800',
          payroll: '$1,920',
          employees: 7,
          insight: 'Thursday night special event increased revenue by 40%. Consider more events.'
        }
      ]
    },
    'This Month': {
      totalBusinesses: 3,
      totalEmployees: 28,
      revenue: '$96,750',
      payroll: '$37,840',
      netProfit: '$58,910',
      businesses: [
        {
          name: 'Downtown Café',
          location: '123 Main St, San Francisco',
          status: 'Active',
          revenue: '$32,500',
          payroll: '$12,600',
          employees: 8,
          insight: 'Coffee sales are up 28% compared to last month. Consider expanding your menu.'
        },
        {
          name: 'Uptown Restaurant',
          location: '456 Oak St, San Francisco',
          status: 'Active',
          revenue: '$48,300',
          payroll: '$18,900',
          employees: 12,
          insight: 'Dinner service outperforms lunch by 3x. Consider reallocating resources.'
        },
        {
          name: 'Lakeside Bar',
          location: '789 Lake Ave, San Francisco',
          status: 'Active',
          revenue: '$15,950',
          payroll: '$6,340',
          employees: 8,
          insight: 'Monthly trivia nights are your most profitable events. Add another weekly event.'
        }
      ]
    },
    'Custom Range': {
      totalBusinesses: 3,
      totalEmployees: 30,
      revenue: '$45,230',
      payroll: '$16,750',
      netProfit: '$28,480',
      businesses: [
        {
          name: 'Downtown Café',
          location: '123 Main St, San Francisco',
          status: 'Active',
          revenue: '$16,230',
          payroll: '$5,980',
          employees: 9,
          insight: 'Your highest revenue period was during the local festival week.'
        },
        {
          name: 'Uptown Restaurant',
          location: '456 Oak St, San Francisco',
          status: 'Active',
          revenue: '$23,700',
          payroll: '$8,350',
          employees: 12,
          insight: 'The promotional discount week generated 40% more customers but only 15% more revenue.'
        },
        {
          name: 'Lakeside Bar',
          location: '789 Lake Ave, San Francisco',
          status: 'Active',
          revenue: '$5,300',
          payroll: '$2,420',
          employees: 9,
          insight: 'Weekday happy hours have consistently low attendance. Consider restructuring or adding entertainment.'
        }
      ]
    }
  };

  // Mock data for scheduling section
  const mockScheduleData = {
    businesses: [
      { id: 'all', name: 'All Businesses' },
      { id: 'cafe', name: 'Cafe Sunshine' },
      { id: 'tech', name: 'Urban Tech Solutions' },
      { id: 'grocery', name: 'Green Grocery' }
    ],
    employees: [
      { id: 1, name: 'Alice Johnson', business: 'cafe' },
      { id: 2, name: 'Bob Smith', business: 'cafe' },
      { id: 3, name: 'Carol Martinez', business: 'tech' },
      { id: 4, name: 'David Kim', business: 'tech' },
      { id: 5, name: 'Eva Brown', business: 'grocery' },
      { id: 6, name: 'Frank Wilson', business: 'grocery' }
    ],
    shifts: [
      { employeeId: 1, day: 'mon', startTime: '7:00 AM', endTime: '3:00 PM', business: 'cafe' },
      { employeeId: 1, day: 'tue', startTime: '7:00 AM', endTime: '3:00 PM', business: 'cafe' },
      { employeeId: 1, day: 'wed', startTime: '7:00 AM', endTime: '3:00 PM', business: 'cafe' },
      { employeeId: 2, day: 'mon', startTime: '3:00 PM', endTime: '11:00 PM', business: 'cafe' },
      { employeeId: 2, day: 'wed', startTime: '3:00 PM', endTime: '11:00 PM', business: 'cafe' },
      { employeeId: 2, day: 'fri', startTime: '3:00 PM', endTime: '11:00 PM', business: 'cafe' },
      { employeeId: 3, day: 'mon', startTime: '9:00 AM', endTime: '5:00 PM', business: 'tech' },
      { employeeId: 3, day: 'tue', startTime: '9:00 AM', endTime: '5:00 PM', business: 'tech' },
      { employeeId: 3, day: 'wed', startTime: '9:00 AM', endTime: '5:00 PM', business: 'tech' },
      { employeeId: 3, day: 'thu', startTime: '9:00 AM', endTime: '5:00 PM', business: 'tech' },
      { employeeId: 3, day: 'fri', startTime: '9:00 AM', endTime: '5:00 PM', business: 'tech' },
      { employeeId: 4, day: 'tue', startTime: '12:00 PM', endTime: '8:00 PM', business: 'tech' },
      { employeeId: 4, day: 'thu', startTime: '12:00 PM', endTime: '8:00 PM', business: 'tech' },
      { employeeId: 5, day: 'mon', startTime: '8:00 AM', endTime: '4:00 PM', business: 'grocery' },
      { employeeId: 5, day: 'wed', startTime: '8:00 AM', endTime: '4:00 PM', business: 'grocery' },
      { employeeId: 5, day: 'fri', startTime: '8:00 AM', endTime: '4:00 PM', business: 'grocery' },
      { employeeId: 6, day: 'tue', startTime: '10:00 AM', endTime: '6:00 PM', business: 'grocery' },
      { employeeId: 6, day: 'thu', startTime: '10:00 AM', endTime: '6:00 PM', business: 'grocery' },
      { employeeId: 6, day: 'sat', startTime: '10:00 AM', endTime: '6:00 PM', business: 'grocery' }
    ],
    aiSuggestions: [
      {
        id: 1,
        type: 'understaffed',
        business: 'grocery',
        day: 'Friday',
        message: 'Green Grocery is currently understaffed on Friday. Would you like to reassign shifts?',
        actions: ['Auto Assign', 'Review Options', 'Ignore']
      },
      {
        id: 2,
        type: 'overlap',
        business: 'tech',
        message: 'Urban Tech Solutions has overlapping shifts. Should I fix it?',
        actions: ['Auto Fix', 'Review', 'Ignore']
      },
      {
        id: 3,
        type: 'unassigned',
        business: 'cafe',
        message: 'One shift at Cafe Sunshine was left unassigned. Should I notify available employees?',
        actions: ['Notify Employees', 'Assign Manually', 'Ignore']
      },
      {
        id: 4,
        type: 'balance',
        business: 'all',
        message: 'Would you like me to balance shift distribution across employees?',
        actions: ['Auto Balance', 'Review Suggestion', 'Ignore']
      }
    ]
  };

  // Mock data for Job Posting & Hiring section
  const mockJobsData = {
    jobPostings: [
      {
        id: 1,
        title: 'Barista',
        business: 'Cafe Sunshine',
        location: '123 Main St, San Francisco',
        payRate: '$17/hour',
        jobType: 'Part-time',
        shiftSchedule: 'Morning shifts (6AM-2PM)',
        benefits: 'Flexible schedule, meal discounts',
        description: 'Cafe Sunshine is looking for an experienced barista to join our morning team. The ideal candidate has 1+ year of experience and excellent customer service skills.',
        status: 'Active',
        datePosted: '2023-05-10',
        applicantsCount: 5
      },
      {
        id: 2,
        title: 'IT Support Specialist',
        business: 'Urban Tech Solutions',
        location: '456 Market St, San Francisco',
        payRate: '$25/hour',
        jobType: 'Full-time',
        shiftSchedule: 'Monday-Friday (9AM-5PM)',
        benefits: 'Health insurance, 401(k), remote work option',
        description: 'Urban Tech Solutions is seeking an IT Support Specialist to assist with technical issues and maintain our computer systems. Must have experience with Windows and macOS troubleshooting.',
        status: 'Active',
        datePosted: '2023-05-05',
        applicantsCount: 8
      },
      {
        id: 3,
        title: 'Cashier',
        business: 'Green Grocery',
        location: '789 Oak St, San Francisco',
        payRate: '$16/hour',
        jobType: 'Part-time',
        shiftSchedule: 'Evenings and weekends',
        benefits: 'Employee discounts',
        description: 'Green Grocery is hiring a part-time cashier for evening and weekend shifts. The ideal candidate is detail-oriented with basic math skills and previous retail experience.',
        status: 'Closed',
        datePosted: '2023-04-20',
        applicantsCount: 12
      }
    ],
    candidates: [
      {
        id: 1,
        name: 'Sophie Williams',
        appliedRole: 'Barista',
        appliedBusiness: 'Cafe Sunshine',
        status: 'Interviewed',
        hourlyRate: '$17/hour',
        dateApplied: '2023-05-11',
        resume: 'resume_sophie_williams.pdf',
        onboarding: {
          w2Submitted: false,
          idVerified: true,
          directDepositSetup: false,
          offerSigned: false
        }
      },
      {
        id: 2,
        name: 'Marcus Chen',
        appliedRole: 'IT Support Specialist',
        appliedBusiness: 'Urban Tech Solutions',
        status: 'Shortlisted',
        hourlyRate: '$25/hour',
        dateApplied: '2023-05-07',
        resume: 'resume_marcus_chen.pdf',
        onboarding: {
          w2Submitted: false,
          idVerified: false,
          directDepositSetup: false,
          offerSigned: false
        }
      },
      {
        id: 3,
        name: 'Taylor Johnson',
        appliedRole: 'Cashier',
        appliedBusiness: 'Green Grocery',
        status: 'Hired',
        hourlyRate: '$16/hour',
        dateApplied: '2023-04-22',
        resume: 'resume_taylor_johnson.pdf',
        onboarding: {
          w2Submitted: true,
          idVerified: true,
          directDepositSetup: true,
          offerSigned: true
        }
      },
      {
        id: 4,
        name: 'Alex Rodriguez',
        appliedRole: 'Barista',
        appliedBusiness: 'Cafe Sunshine',
        status: 'Applied',
        hourlyRate: '$17/hour',
        dateApplied: '2023-05-12',
        resume: 'resume_alex_rodriguez.pdf',
        onboarding: {
          w2Submitted: false,
          idVerified: false,
          directDepositSetup: false,
          offerSigned: false
        }
      },
      {
        id: 5,
        name: 'Jordan Davis',
        appliedRole: 'IT Support Specialist',
        appliedBusiness: 'Urban Tech Solutions',
        status: 'Rejected',
        hourlyRate: '$25/hour',
        dateApplied: '2023-05-08',
        resume: 'resume_jordan_davis.pdf',
        onboarding: {
          w2Submitted: false,
          idVerified: false,
          directDepositSetup: false,
          offerSigned: false
        }
      }
    ],
    hiringInsights: [
      {
        id: 1,
        type: 'applicant',
        message: '3 new applicants for Urban Tech Solutions in the last week.',
        actionable: true,
        action: 'View Applicants'
      },
      {
        id: 2,
        type: 'spending',
        message: 'You\'ve spent $3,400 on hiring so far this month.',
        actionable: false
      },
      {
        id: 3,
        type: 'openPosition',
        message: '2 positions are still open for Green Grocery.',
        actionable: true,
        action: 'View Open Positions'
      },
      {
        id: 4,
        type: 'onboarding',
        message: 'Taylor Johnson has completed all onboarding steps and is ready to start!',
        actionable: true,
        action: 'View Onboarding'
      }
    ]
  };

  // Payroll Management States
  const [selectedPayrollBusiness, setSelectedPayrollBusiness] = useState('');
  const [payFrequency, setPayFrequency] = useState('weekly');
  const [expandedEmployees, setExpandedEmployees] = useState([]);
  const [payrollChatInput, setPayrollChatInput] = useState('');
  const [payrollChatMessages, setPayrollChatMessages] = useState([
    { sender: 'ai', message: 'Hi! I can help you with payroll questions. What would you like to know?' }
  ]);

  // Mock employee data for payroll
  const mockEmployees = [
    {
      id: 1,
      name: 'John Doe',
      role: 'Cashier',
      hours: 38,
      rate: 15,
      grossPay: '570.00',
      status: 'Paid',
      payDetails: {
        gross: '570.00',
        taxes: '45.00',
        net: '525.00',
        overtime: '45.00',
        bonuses: '0.00',
        deductions: '20.00'
      }
    },
    {
      id: 2,
      name: 'Sarah Smith',
      role: 'Barista',
      hours: 40,
      rate: 14,
      grossPay: '560.00',
      status: 'Paid',
      payDetails: {
        gross: '560.00',
        taxes: '42.00',
        net: '518.00',
        overtime: '0.00',
        bonuses: '0.00',
        deductions: '15.00'
      }
    },
    {
      id: 3,
      name: 'Emma Watson',
      role: 'Server',
      hours: 24,
      rate: 16,
      grossPay: '384.00',
      status: 'Pending',
      payDetails: {
        gross: '384.00',
        taxes: '30.00',
        net: '354.00',
        overtime: '0.00',
        bonuses: '0.00',
        deductions: '10.00'
      }
    },
    {
      id: 4,
      name: 'Mike Jordan',
      role: 'Kitchen Staff',
      hours: 45,
      rate: 15,
      grossPay: '675.00',
      status: 'Paid',
      payDetails: {
        gross: '675.00',
        taxes: '52.00',
        net: '623.00',
        overtime: '75.00',
        bonuses: '50.00',
        deductions: '25.00'
      }
    }
  ];

  // Payroll Management Handlers
  const handlePayPeriodChange = (direction) => {
    // TODO: Implement pay period navigation
    console.log('Changing pay period:', direction);
  };

  const toggleEmployeeDetails = (employeeId) => {
    setExpandedEmployees(prev => 
      prev.includes(employeeId)
        ? prev.filter(id => id !== employeeId)
        : [...prev, employeeId]
    );
  };

  const handlePayrollChatSend = () => {
    if (!payrollChatInput.trim()) return;

    // Add user message
    setPayrollChatMessages(prev => [
      ...prev,
      { sender: 'user', message: payrollChatInput }
    ]);

    // Simulate AI response based on keywords
    let aiResponse = '';
    const input = payrollChatInput.toLowerCase();

    if (input.includes('spend') && input.includes('cafe sunshine')) {
      aiResponse = 'Last month\'s total payroll for Cafe Sunshine was $12,450. This includes regular wages, overtime, and bonuses.';
    } else if (input.includes('overtime')) {
      aiResponse = 'This week, 2 employees worked overtime: Mike Jordan (5 hours) and John Doe (3 hours).';
    } else if (input.includes('tax')) {
      aiResponse = 'Your current quarter tax liability is $3,240. This includes federal and state withholdings.';
    } else {
      aiResponse = 'I\'ll help you find that information. Could you please provide more details about what you\'re looking for?';
    }

    // Add AI response with a small delay to simulate processing
    setTimeout(() => {
      setPayrollChatMessages(prev => [
        ...prev,
        { sender: 'ai', message: aiResponse }
      ]);
    }, 500);

    // Clear input
    setPayrollChatInput('');
  };

  useEffect(() => {
    console.log('=== State Change Log ===');
    console.log('isProfileOpen:', isProfileOpen);
    console.log('isLoading:', isLoading);
    console.log('hasData:', !!employerData);
    console.log('error:', error);
  }, [isProfileOpen, isLoading, employerData, error]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      console.log('=== Click Outside Handler ===');
      const profileButton = document.getElementById('profile-button');
      const profileDropdown = document.getElementById('profile-dropdown');
      
      console.log('Elements found:', {
        button: !!profileButton,
        dropdown: !!profileDropdown
      });

      if (profileButton && profileDropdown) {
        const isClickInside = profileButton.contains(event.target) || 
                            profileDropdown.contains(event.target);
        
        console.log('Click location:', {
          isClickInside,
          target: event.target.id || event.target.className
        });

        if (!isClickInside) {
          console.log('Closing dropdown - click outside');
          setIsProfileOpen(false);
        }
      }
    };

    if (isProfileOpen) {
      console.log('Adding click outside listener');
      const frameId = requestAnimationFrame(() => {
        document.addEventListener('mousedown', handleClickOutside);
      });

      return () => {
        console.log('Cleaning up click outside listener');
        cancelAnimationFrame(frameId);
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [isProfileOpen]);

  const fetchEmployerData = useCallback(async () => {
    console.log('=== Starting fetchEmployerData ===');
    console.log('Initial state:', {
      isLoading,
      hasData: !!employerData,
      error
    });
    
    setIsLoading(true);
    setError(null);

    try {
      const user = auth.currentUser;
      console.log('Auth state:', {
        userExists: !!user,
        uid: user?.uid,
        email: user?.email
      });

      if (!user) {
        console.log('No user found, redirecting to sign in');
        navigate('/employer-signin');
        return;
      }

      const employerRef = doc(db, 'employers', user.uid);
      console.log('Fetching document for user:', user.uid);
      
      const employerDoc = await getDoc(employerRef);
      console.log('Document status:', {
        exists: employerDoc.exists(),
        id: employerDoc.id
      });

      if (employerDoc.exists()) {
        const data = employerDoc.data();
        console.log('Setting existing employer data');
        setEmployerData(data);
      } else {
        console.log('Creating new employer document');
        const employerId = await generateUniqueEmployerId();
        const newEmployerData = {
          employerId,
          email: user.email,
          name: user.displayName || '',
          phone: '',
          createdAt: new Date().toISOString(),
          lastUpdated: new Date().toISOString()
        };
        
        console.log('Saving new employer data');
        await setDoc(employerRef, newEmployerData);
        setEmployerData(newEmployerData);
      }
    } catch (error) {
      console.error('Error in fetchEmployerData:', {
        code: error.code,
        message: error.message
      });
      setError(error.message);
    } finally {
      console.log('Setting loading to false');
      setIsLoading(false);
    }
  }, [navigate]);

  useEffect(() => {
    console.log('=== Setting up auth listener ===');
    const unsubscribe = auth.onAuthStateChanged((user) => {
      console.log('Auth state changed:', {
        userExists: !!user,
        uid: user?.uid,
        email: user?.email,
        isAnonymous: user?.isAnonymous,
        emailVerified: user?.emailVerified
      });
      
      if (user) {
        console.log('User authenticated, fetching data');
        fetchEmployerData();
      } else {
        console.log('No user, redirecting to sign in');
        navigate('/employer-signin');
      }
    }, (error) => {
      console.error('Auth state change error:', {
        code: error.code,
        message: error.message,
        stack: error.stack
      });
      setError('Authentication error. Please try signing in again.');
    });

    return () => {
      console.log('Cleaning up auth listener');
      unsubscribe();
    };
  }, [fetchEmployerData, navigate]);

  useEffect(() => {
    return () => {
      console.log('=== Component Unmount ===');
      console.log('Final state:', {
        isLoading,
        employerData,
        error,
        isProfileOpen
      });
      setIsLoading(false);
      setError(null);
      setEmployerData(null);
      setIsProfileOpen(false);
    };
  }, []);

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleProfileSave = async (data) => {
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('No authenticated user found');
      }

      // Validate data before saving
      if (!data.name || !data.phone) {
        throw new Error('Name and phone number are required');
      }

      const employerRef = doc(db, 'employers', user.uid);
      
      // Verify the document exists before updating
      const docSnap = await getDoc(employerRef);
      if (!docSnap.exists()) {
        throw new Error('Employer profile not found');
      }

      // Update with merge to preserve other fields
      await setDoc(employerRef, { ...employerData, ...data }, { merge: true });
      
      // Verify the update was successful
      const updatedDoc = await getDoc(employerRef);
      const updatedData = updatedDoc.data();
      
      if (updatedData.name !== data.name || updatedData.phone !== data.phone) {
        throw new Error('Failed to update profile data');
      }
      
      setEmployerData((prev) => ({ ...prev, ...data }));
      return Promise.resolve();
    } catch (error) {
      console.error('Error saving profile:', error);
      return Promise.reject(error);
    }
  };

  // Navigation links
  const navLinks = [
    { id: 'business', label: 'Business & Employees', icon: faBuilding },
    { id: 'scheduling', label: 'Scheduling & Shift Management', icon: faCalendar },
    { id: 'jobs', label: 'Job Posting & Hiring', icon: faBriefcase },
    { id: 'payroll', label: 'Payroll Management', icon: faDollarSign }
  ];

  // Handle changing time range
  const handleTimeRangeChange = (e) => {
    const value = e.target.value;
    setSelectedTimeRange(value);
    
    if (value === 'Custom Range') {
      setIsDateRangeModalOpen(true);
    }
  };

  // Handle applying custom date range
  const handleApplyDateRange = (startDate, endDate) => {
    setCustomDateRange({ startDate, endDate });
    // Already set to "Custom Range" in selectedTimeRange
  };

  // Helper functions for the scheduling section
  const getFilteredEmployees = () => {
    if (selectedBusiness === 'all') {
      return mockScheduleData.employees;
    }
    return mockScheduleData.employees.filter(employee => employee.business === selectedBusiness);
  };

  const getShiftForCell = (employeeId, day) => {
    return mockScheduleData.shifts.find(
      shift => shift.employeeId === employeeId && shift.day === day
    );
  };

  const handleCellClick = (employeeId, day) => {
    const shift = getShiftForCell(employeeId, day);
    setCurrentShift(shift || { employeeId, day, startTime: '9:00 AM', endTime: '5:00 PM' });
    setCurrentCell({ employeeId, day });
    setIsShiftModalOpen(true);
  };

  const handleBusinessChange = (e) => {
    setSelectedBusiness(e.target.value);
  };

  const getFilteredAiSuggestions = () => {
    if (selectedBusiness === 'all') {
      return mockScheduleData.aiSuggestions;
    }
    return mockScheduleData.aiSuggestions.filter(
      suggestion => suggestion.business === selectedBusiness || suggestion.business === 'all'
    );
  };

  // Handle saving shifts
  const handleSaveShift = (shift) => {
    console.log('Saving shift:', shift);
    // In a real app, this would update the database
    // For the demo, we'll just close the modal
    setIsShiftModalOpen(false);
  };

  // Handle sending messages in the AI chat
  const handleSendMessage = (text) => {
    // Add user message
    setChatMessages([...chatMessages, { sender: 'user', text }]);
    setNewMessage('');
    setIsProcessing(true);
    
    // Determine response time based on message complexity
    const baseDelay = 800;
    const wordCount = text.split(' ').length;
    const responseTime = baseDelay + (wordCount * 100);
    
    // Remember last topics for context awareness
    const lastMessages = chatMessages.slice(-2);
    const isFollowUp = lastMessages.length > 0 && 
                       lastMessages[lastMessages.length-1].sender === 'ai' && 
                       (text.toLowerCase().includes('yes') || 
                        text.toLowerCase().includes('no') || 
                        text.toLowerCase().includes('please') ||
                        text.toLowerCase().includes('ok') ||
                        text.toLowerCase().includes('thanks') ||
                        text.length < 10);
    
    // Simulate AI processing
    setTimeout(() => {
      let aiResponse = "I'll analyze that and get back to you shortly.";
      
      // Context-aware responses
      if (isFollowUp) {
        const lastAiMessage = lastMessages[lastMessages.length-1].text;
        
        if (text.toLowerCase().includes('yes') || text.toLowerCase().includes('ok') || text.toLowerCase().includes('please')) {
          if (lastAiMessage.includes('overtime')) {
            aiResponse = "I'll adjust the schedule to reduce overtime. Bob Smith's hours will be reduced by 4, Sarah Johnson by 2, and we'll reassign David Kim's Saturday shift to Eva Brown. This will save approximately $420 in payroll this week. Would you like me to implement these changes?";
          } else if (lastAiMessage.includes('conflicts')) {
            aiResponse = "I've resolved the scheduling conflicts. Carol Martinez will now work only at Urban Tech Solutions on Monday, and Frank Wilson's shifts have been adjusted to avoid overlap. The updated schedule is ready for your review and approval.";
          } else if (lastAiMessage.includes('redistribute')) {
            aiResponse = "I've redistributed 5 of Alice's shifts to Bob (2 shifts) and Eva (3 shifts). This brings Alice down to 13 shifts this month, which keeps her below overtime threshold. Would you like to see the updated schedule?";
          } else if (lastAiMessage.includes('uncovered shifts')) {
            aiResponse = "I've identified qualified employees for each uncovered shift. For Tuesday evening at Cafe Sunshine: Bob Smith is available. For Saturday morning: Eva Brown is available. For Thursday afternoon at Urban Tech: Carol Martinez can extend her shift. Should I assign them automatically?";
          }
        } else if (text.toLowerCase().includes('no')) {
          aiResponse = "I understand. The current schedule will remain unchanged. Is there anything else I can help you analyze or optimize about the current schedule?";
        }
      }
      // Keyword-based responses
      else if (text.toLowerCase().includes('overtime')) {
        aiResponse = aiResponses.overtime;
      } else if (text.toLowerCase().includes('conflict')) {
        aiResponse = aiResponses.conflicts;
      } else if (text.toLowerCase().includes('optimize') || text.toLowerCase().includes('optimiz')) {
        aiResponse = aiResponses.optimize;
      } else if (text.toLowerCase().includes('most shift')) {
        aiResponse = aiResponses.most_shifts;
      } else if (text.toLowerCase().includes('uncovered') || text.toLowerCase().includes('coverage')) {
        aiResponse = aiResponses.uncovered;
      } else if (text.toLowerCase().includes('vacation') || text.toLowerCase().includes('time off')) {
        aiResponse = aiResponses.vacation;
      } else if (text.toLowerCase().includes('employees available')) {
        aiResponse = aiResponses.employees_available;
      } else if (text.toLowerCase().includes('shift distribution')) {
        aiResponse = aiResponses.shift_distribution;
      } else if (text.toLowerCase().includes('peak hours')) {
        aiResponse = aiResponses.peak_hours;
      } else if (text.toLowerCase().includes('employee performance') || text.toLowerCase().includes('top perform')) {
        aiResponse = aiResponses.employee_performance;
      } else if (text.toLowerCase().includes('hello') || text.toLowerCase().includes('hi')) {
        aiResponse = "Hello! I'm your WorkFission AI assistant. I can help you with scheduling, shift management, employee allocation, and staffing optimization. What can I help you with today?";
      } else if (text.toLowerCase().includes('thank')) {
        aiResponse = "You're welcome! Is there anything else I can help you with regarding your schedule management?";
      } else if (text.toLowerCase().includes('help')) {
        aiResponse = "I can help with shift scheduling, employee allocation, identifying conflicts, analyzing staffing efficiency, and providing AI-driven optimization suggestions. What specific area would you like assistance with?";
      }
      
      // Add AI response after a realistic delay
      setChatMessages(prev => [...prev, { sender: 'ai', text: aiResponse }]);
      setIsProcessing(false);
    }, responseTime);
  };
  
  // Handle clicking on suggested questions
  const handleSuggestedQuestion = (question) => {
    setNewMessage(question);
  };

  // Handle clicking on navigation items
  const handleNavLinkClick = (sectionId, e) => {
    e.preventDefault();
    setActiveSection(sectionId);
  };

  // Job Posting & Hiring handlers
  const handleJobChatSend = (message) => {
    // Add user message to chat
    setJobChatMessages([...jobChatMessages, { sender: 'user', text: message }]);
    setJobChatInput('');
    setIsProcessingJobAI(true);
    
    // Simulate AI processing
    setTimeout(() => {
      // Check if the message is a job creation request
      if (message.toLowerCase().includes('create') || 
          message.toLowerCase().includes('post') || 
          message.toLowerCase().includes('job')) {
        
        // Extract job details from the message
        let newJobData = { ...jobFormData };
        
        // Simple parsing logic (this would be more sophisticated in a real AI system)
        if (message.toLowerCase().includes('part-time')) {
          newJobData.jobType = 'Part-time';
        } else if (message.toLowerCase().includes('full-time')) {
          newJobData.jobType = 'Full-time';
        }
        
        if (message.toLowerCase().includes('cashier')) {
          newJobData.title = 'Cashier';
        } else if (message.toLowerCase().includes('barista')) {
          newJobData.title = 'Barista';
        } else if (message.toLowerCase().includes('delivery')) {
          newJobData.title = 'Delivery Driver';
        }
        
        if (message.toLowerCase().includes('cafe') || message.toLowerCase().includes('coffee')) {
          newJobData.business = 'Cafe Sunshine';
          newJobData.location = '123 Main St, San Francisco';
        } else if (message.toLowerCase().includes('tech')) {
          newJobData.business = 'Urban Tech Solutions';
          newJobData.location = '456 Market St, San Francisco';
        } else if (message.toLowerCase().includes('grocery')) {
          newJobData.business = 'Green Grocery';
          newJobData.location = '789 Oak St, San Francisco';
        }
        
        // Extract pay rate
        const payMatch = message.match(/\$(\d+)(\/hour|\/hr|per hour)?/);
        if (payMatch) {
          newJobData.payRate = `$${payMatch[1]}/hour`;
        }
        
        // Extract shift info
        if (message.toLowerCase().includes('morning')) {
          newJobData.shiftSchedule = 'Morning shifts (6AM-2PM)';
        } else if (message.toLowerCase().includes('evening')) {
          newJobData.shiftSchedule = 'Evening shifts (2PM-10PM)';
        } else if (message.toLowerCase().includes('weekend')) {
          newJobData.shiftSchedule = 'Weekend shifts';
        }
        
        // Generate a basic description
        newJobData.description = `${newJobData.business} is looking for a ${newJobData.jobType} ${newJobData.title}. This position pays ${newJobData.payRate} with ${newJobData.shiftSchedule}.`;
        
        // Add benefits
        if (newJobData.jobType === 'Full-time') {
          newJobData.benefits = 'Health insurance, 401(k), paid time off';
        } else {
          newJobData.benefits = 'Flexible schedule, employee discounts';
        }
        
        // Update state
        setJobFormData(newJobData);
        
        // AI response
        setJobChatMessages(prev => [...prev, { 
          sender: 'ai', 
          text: `I've created a job posting for ${newJobData.title} at ${newJobData.business}. You can review and edit it in the form below before posting.` 
        }]);
        
        // Switch to job creation mode
        setJobCreationMode(true);
      } else {
        // Generic response
        setJobChatMessages(prev => [...prev, { 
          sender: 'ai', 
          text: 'To create a job posting, please provide details like: job title, business, pay rate, job type (full/part-time), and any specific requirements. For example: "Create a part-time cashier job for Green Grocery. $15/hour, morning shifts only."' 
        }]);
      }
      
      setIsProcessingJobAI(false);
    }, 1500);
  };
  
  const handleJobFormChange = (e) => {
    const { name, value } = e.target;
    setJobFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };
  
  const handlePostJob = () => {
    // In a real app, this would save to database
    // For now, just log and reset
    console.log('Job Posted:', jobFormData);
    
    // Add success message to chat
    setJobChatMessages(prev => [...prev, { 
      sender: 'ai', 
      text: `Great! Your job posting for ${jobFormData.title} at ${jobFormData.business} has been published. You can track applicants in the Hiring & Onboarding Dashboard.` 
    }]);
    
    // Reset form and mode
    setJobFormData({
      title: '',
      business: '',
      location: '',
      payRate: '',
      jobType: 'Full-time',
      shiftSchedule: '',
      benefits: '',
      description: '',
      requirements: ''
    });
    setJobCreationMode(false);
    setSelectedJobTab('hiring');
  };
  
  const handleCancelJobCreation = () => {
    setJobCreationMode(false);
    setJobFormData({
      title: '',
      business: '',
      location: '',
      payRate: '',
      jobType: 'Full-time',
      shiftSchedule: '',
      benefits: '',
      description: '',
      requirements: ''
    });
    setJobChatMessages(prev => [...prev, { 
      sender: 'ai', 
      text: 'Job creation cancelled. How else can I help you today?' 
    }]);
  };
  
  const getFilteredCandidates = () => {
    if (selectedCandidateFilter === 'all') {
      return mockJobsData.candidates;
    }
    return mockJobsData.candidates.filter(candidate => 
      candidate.status.toLowerCase() === selectedCandidateFilter.toLowerCase()
    );
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Navigation */}
      <nav className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <div className="flex-shrink-0 flex items-center">
                <h1 className="text-xl font-bold text-[#003A9B]">WorkFission</h1>
            </div>
              <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                {navLinks.map(link => (
                <NavLink
                  key={link.id}
                    href="#"
                  isActive={activeSection === link.id}
                    onClick={(e) => handleNavLinkClick(link.id, e)}
                >
                    <FontAwesomeIcon icon={link.icon} className="mr-2" />
                  {link.label}
                </NavLink>
              ))}
              </div>
            </div>

            <div className="relative">
              <button
                id="profile-button"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  console.log('=== Profile Button Click ===');
                  console.log('Current state:', isProfileOpen);
                  setIsProfileOpen(prev => {
                    console.log('Setting isProfileOpen to:', !prev);
                    return !prev;
                  });
                }}
                className="flex items-center space-x-2 bg-white text-[#1C4E80] px-4 py-2 rounded-md hover:bg-gray-100 transition-colors duration-300"
              >
                <FontAwesomeIcon icon={faUser} />
                <FontAwesomeIcon icon={faCaretDown} className="text-xs" />
              </button>

              <div 
                id="profile-dropdown"
                className="absolute right-0 mt-2"
                style={{ zIndex: 1000 }}
              >
                <ProfileDropdown
                  isOpen={isProfileOpen}
                  employerData={employerData}
                  onSave={handleProfileSave}
                  onSignOut={handleSignOut}
                  isLoading={isLoading}
                  error={error}
                />
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Section Title */}
        <div className="mb-6">
          <h2 className="text-2xl font-bold text-gray-900">
            {navLinks.find(link => link.id === activeSection)?.label || 'Dashboard'}
          </h2>
          <p className="mt-1 text-sm text-gray-500">
            {activeSection === 'business' && 'Manage your businesses and employees'}
            {activeSection === 'scheduling' && 'Create and manage employee schedules'}
            {activeSection === 'jobs' && 'Create job postings, track applicants, and manage the hiring process'}
            {activeSection === 'payroll' && 'Manage payroll, view reports, and process payments'}
          </p>
        </div>

        {/* Section Content */}
        <div className="bg-white shadow rounded-lg p-6">
          {/* Payroll Management Section */}
          {activeSection === 'payroll' && (
            <div className="space-y-6">
              <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
                {/* Business Selector */}
                <div className="w-full md:w-64">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Select Business
                  </label>
                  <select
                    value={selectedPayrollBusiness}
                    onChange={(e) => setSelectedPayrollBusiness(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-[#003A9B] focus:border-[#003A9B]"
                  >
                    <option value="">All Businesses</option>
                    <option value="Green Grocery">Green Grocery</option>
                    <option value="Cafe Sunshine">Cafe Sunshine</option>
                    <option value="Urban Tech Solutions">Urban Tech Solutions</option>
                  </select>
                </div>

                {/* Pay Period Navigation */}
                <div className="flex flex-col items-end space-y-2">
                  <div className="flex items-center space-x-4">
                    <button 
                      onClick={() => handlePayPeriodChange('prev')}
                      className="p-2 text-gray-600 hover:text-gray-900"
                    >
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </button>
                    <div className="text-sm font-medium">
                      Week: Mar 17 – Mar 23
                    </div>
                    <button 
                      onClick={() => handlePayPeriodChange('next')}
                      className="p-2 text-gray-600 hover:text-gray-900"
                    >
                      <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                  </div>
                  <select
                    value={payFrequency}
                    onChange={(e) => setPayFrequency(e.target.value)}
                    className="text-sm border border-gray-300 rounded px-2 py-1"
                  >
                    <option value="weekly">Weekly</option>
                    <option value="bi-weekly">Bi-Weekly</option>
                    <option value="monthly">Monthly</option>
                  </select>
                </div>
              </div>

              {/* Main Content Grid */}
              <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
                {/* Employee Cards Grid */}
                <div className="lg:col-span-3 space-y-4">
                  {mockEmployees.map((employee) => (
                    <div key={employee.id} className="bg-white rounded-lg shadow-sm">
                      <div className="p-4">
                        {/* Employee Header */}
                        <div className="flex items-center justify-between mb-4">
                          <div className="flex items-center space-x-3">
                            <div className="w-10 h-10 bg-gray-200 rounded-full flex items-center justify-center">
                              <FontAwesomeIcon icon={faUser} className="text-gray-600" />
                            </div>
                            <div>
                              <h3 className="font-medium text-gray-900">{employee.name}</h3>
                              <p className="text-sm text-gray-500">{employee.role}</p>
                            </div>
                          </div>
                          <span className={`px-2 py-1 text-xs font-medium rounded-full ${
                            employee.status === 'Paid' 
                              ? 'bg-green-100 text-green-800' 
                              : 'bg-yellow-100 text-yellow-800'
                          }`}>
                            {employee.status}
                          </span>
                        </div>

                        {/* Pay Summary */}
                        <div className="grid grid-cols-3 gap-4 mb-4">
                          <div>
                            <p className="text-sm text-gray-500">Total Hours</p>
                            <p className="font-medium">{employee.hours} hrs</p>
                          </div>
                          <div>
                            <p className="text-sm text-gray-500">Hourly Rate</p>
                            <p className="font-medium">${employee.rate}/hr</p>
                          </div>
                          <div>
                            <p className="text-sm text-gray-500">Gross Pay</p>
                            <p className="font-medium">${employee.grossPay}</p>
                          </div>
                        </div>

                        {/* Expandable Details */}
                        <div className="border-t pt-4">
                          <button 
                            onClick={() => toggleEmployeeDetails(employee.id)}
                            className="flex items-center text-sm text-gray-600 hover:text-gray-900"
                          >
                            <FontAwesomeIcon 
                              icon={expandedEmployees.includes(employee.id) ? faChevronUp : faChevronDown} 
                              className="mr-2" 
                            />
                            {expandedEmployees.includes(employee.id) ? 'Hide Details' : 'Show Details'}
                          </button>
                          
                          {expandedEmployees.includes(employee.id) && (
                            <div className="mt-4 space-y-3">
                              <div className="grid grid-cols-2 gap-4">
                                <div>
                                  <p className="text-sm text-gray-500">Gross Pay</p>
                                  <p className="font-medium">${employee.payDetails.gross}</p>
                                </div>
                                <div>
                                  <p className="text-sm text-gray-500">Taxes Withheld</p>
                                  <p className="font-medium">${employee.payDetails.taxes}</p>
                                </div>
                                <div>
                                  <p className="text-sm text-gray-500">Net Pay</p>
                                  <p className="font-medium">${employee.payDetails.net}</p>
                                </div>
                                <div>
                                  <p className="text-sm text-gray-500">Overtime</p>
                                  <p className="font-medium">${employee.payDetails.overtime}</p>
                                </div>
                                <div>
                                  <p className="text-sm text-gray-500">Bonuses</p>
                                  <p className="font-medium">${employee.payDetails.bonuses}</p>
                                </div>
                                <div>
                                  <p className="text-sm text-gray-500">Deductions</p>
                                  <p className="font-medium">${employee.payDetails.deductions}</p>
                                </div>
                              </div>
                              
                              <div className="flex justify-end space-x-3 mt-4">
                                <button className="text-sm text-[#003A9B] hover:text-[#0046bd]">
                                  Download Pay Stub
                                </button>
                                <button className="text-sm text-[#003A9B] hover:text-[#0046bd]">
                                  Send to Employee
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
            ))}
          </div>

                {/* Right Sidebar */}
                <div className="lg:col-span-1 space-y-6">
                  {/* Insights Panel */}
                  <div className="bg-white rounded-lg shadow-sm p-4">
                    <h3 className="font-medium text-gray-900 mb-4">Payroll Insights</h3>
                    <div className="space-y-4">
                      <div>
                        <p className="text-sm text-gray-500">Total Payroll Cost</p>
                        <p className="text-xl font-semibold text-gray-900">$4,350</p>
        </div>
                      <div>
                        <p className="text-sm text-gray-500">Employees Paid</p>
                        <p className="text-xl font-semibold text-gray-900">6</p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-500">Tax Liability</p>
                        <p className="text-xl font-semibold text-gray-900">$790</p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-500">Overtime Hours</p>
                        <p className="text-xl font-semibold text-gray-900">12</p>
                      </div>
                      <div className="pt-2 border-t">
                        <p className="text-sm text-gray-500">vs Last Week</p>
                        <p className="text-sm font-medium text-green-600">+7% ($290)</p>
                      </div>
                    </div>
                  </div>

                  {/* AI Payroll Assistant */}
                  <div className="bg-white rounded-lg shadow-sm p-4">
                    <div className="flex items-center space-x-2 mb-4">
                      <FontAwesomeIcon icon={faRobot} className="text-[#003A9B]" />
                      <h3 className="font-medium text-gray-900">AI Payroll Assistant</h3>
                    </div>
                    
                    <div className="space-y-4">
                      <div className="h-64 overflow-y-auto border rounded-lg p-3 bg-gray-50">
                        {payrollChatMessages.map((msg, index) => (
                          <div key={index} className={`mb-3 ${
                            msg.sender === 'ai' ? 'pl-2' : 'pl-4'
                          }`}>
                            <p className={`text-sm ${
                              msg.sender === 'ai' ? 'text-gray-800' : 'text-gray-600'
                            }`}>
                              {msg.message}
                            </p>
                          </div>
                        ))}
          </div>

                      <div className="flex items-center space-x-2">
                        <input
                          type="text"
                          value={payrollChatInput}
                          onChange={(e) => setPayrollChatInput(e.target.value)}
                          placeholder="Ask about payroll..."
                          className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-[#003A9B] focus:border-[#003A9B]"
                        />
                        <button
                          onClick={handlePayrollChatSend}
                          className="px-4 py-2 bg-[#003A9B] text-white rounded hover:bg-[#0046bd]"
                        >
                          Ask
                        </button>
                      </div>
                      
                      <div>
                        <p className="text-xs text-gray-500 mb-2">Try asking:</p>
                        <div className="space-y-2">
                          <button 
                            onClick={() => setPayrollChatInput("How much did I spend on Cafe Sunshine last month?")}
                            className="text-xs bg-gray-100 hover:bg-gray-200 px-3 py-1 rounded-full text-gray-600 block w-full text-left"
                          >
                            "How much did I spend on Cafe Sunshine last month?"
                          </button>
                          <button 
                            onClick={() => setPayrollChatInput("Show me employees who worked overtime this week")}
                            className="text-xs bg-gray-100 hover:bg-gray-200 px-3 py-1 rounded-full text-gray-600 block w-full text-left"
                          >
                            "Show me employees who worked overtime this week"
                          </button>
                          <button 
                            onClick={() => setPayrollChatInput("What's my total tax liability for this quarter?")}
                            className="text-xs bg-gray-100 hover:bg-gray-200 px-3 py-1 rounded-full text-gray-600 block w-full text-left"
                          >
                            "What's my total tax liability for this quarter?"
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          
          {activeSection === 'business' && (
            <div className="space-y-6">
              {/* Page Header */}
              <div className="bg-white rounded-lg shadow-sm p-6">
                <h1 className="text-2xl font-bold text-gray-900">Business & Employees Dashboard</h1>
                <p className="text-gray-600 mt-1">Manage your businesses, employees, and view key metrics</p>
              </div>
              
              {/* Overview Section */}
              <div className="bg-white rounded-lg shadow-sm p-6">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-lg font-semibold text-gray-900">Overview</h2>
                  <div className="flex items-center space-x-2">
                    <select 
                      className="text-sm border border-gray-300 rounded px-3 py-1"
                      value={selectedTimeRange}
                      onChange={handleTimeRangeChange}
                    >
                      <option>Today</option>
                      <option>This Week</option>
                      <option>This Month</option>
                      <option>Custom Range</option>
                    </select>
                    {selectedTimeRange === 'Custom Range' && customDateRange.startDate && (
                      <div className="text-sm bg-blue-50 text-blue-700 px-2 py-1 rounded flex items-center">
                        <FontAwesomeIcon icon={faCalendar} className="mr-1" />
                        <span>
                          {new Date(customDateRange.startDate).toLocaleDateString()} - {new Date(customDateRange.endDate).toLocaleDateString()}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
                  {/* Total Businesses */}
                  <div className="bg-blue-50 p-4 rounded-lg">
                    <div className="text-sm text-gray-500">Total Businesses</div>
                    <div className="text-2xl font-bold text-gray-900">{mockData[selectedTimeRange].totalBusinesses}</div>
                  </div>
                  
                  {/* Total Employees */}
                  <div className="bg-blue-50 p-4 rounded-lg">
                    <div className="text-sm text-gray-500">Total Employees</div>
                    <div className="text-2xl font-bold text-gray-900">{mockData[selectedTimeRange].totalEmployees}</div>
                  </div>
                  
                  {/* Today's Revenue */}
                  <div className="bg-blue-50 p-4 rounded-lg">
                    <div className="text-sm text-gray-500">{selectedTimeRange} Revenue</div>
                    <div className="text-2xl font-bold text-gray-900">{mockData[selectedTimeRange].revenue}</div>
                  </div>
                  
                  {/* Today's Payroll */}
                  <div className="bg-blue-50 p-4 rounded-lg">
                    <div className="text-sm text-gray-500">{selectedTimeRange} Payroll</div>
                    <div className="text-2xl font-bold text-gray-900">{mockData[selectedTimeRange].payroll}</div>
                  </div>
                  
                  {/* Net Profit */}
                  <div className="bg-blue-50 p-4 rounded-lg">
                    <div className="text-sm text-gray-500">Net Profit</div>
                    <div className="text-2xl font-bold text-gray-900">{mockData[selectedTimeRange].netProfit}</div>
                  </div>
                </div>
              </div>
              
              {/* Business Cards Grid */}
              <div className="bg-white rounded-lg shadow-sm p-6">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-lg font-semibold text-gray-900">Your Businesses</h2>
                  <div className="flex items-center space-x-2">
                    <button className="text-sm bg-[#003A9B] text-white px-4 py-2 rounded hover:bg-[#0046bd] transition-colors">
                      Add Business
                    </button>
                    <select className="text-sm border border-gray-300 rounded px-3 py-1">
                      <option>Grid View</option>
                      <option>Table View</option>
                    </select>
                  </div>
                </div>
                
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {mockData[selectedTimeRange].businesses.map((business) => (
                    <div key={business.name} className="border border-gray-200 rounded-lg p-4 hover:border-blue-300 transition-colors">
                      <div className="flex justify-between">
                        <h3 className="font-semibold text-gray-900">{business.name}</h3>
                        <span className="bg-green-100 text-green-800 text-xs px-2 py-1 rounded">Active</span>
                      </div>
                      <p className="text-gray-500 text-sm">{business.location}</p>
                      
                      <div className="mt-4 space-y-2">
                        <div className="flex justify-between text-sm">
                          <span className="text-gray-500">{selectedTimeRange} Revenue:</span>
                          <span className="font-medium">{business.revenue}</span>
                        </div>
                        <div className="flex justify-between text-sm">
                          <span className="text-gray-500">{selectedTimeRange} Payroll:</span>
                          <span className="font-medium">{business.payroll}</span>
                        </div>
                        <div className="flex justify-between text-sm">
                          <span className="text-gray-500">Employees:</span>
                          <span className="font-medium">{business.employees}</span>
                        </div>
                      </div>
                      
                      <div className="mt-3 bg-blue-50 p-3 rounded text-sm">
                        <div className="flex items-start">
                          <div className="text-blue-600 mr-2 mt-0.5">
                            <FontAwesomeIcon icon={faLightbulb} />
                          </div>
                          <p className="text-gray-700">
                            <span className="font-medium">AI Insight:</span> {business.insight}
                          </p>
                        </div>
          </div>

                      <div className="mt-4">
                        <button className="w-full text-[#003A9B] border border-[#003A9B] px-3 py-2 rounded text-sm hover:bg-blue-50 transition-colors">
                          View Detailed Report
                        </button>
                      </div>
                    </div>
          ))}
        </div>
              </div>
              
              {/* Employee Overview */}
              <div className="bg-white rounded-lg shadow-sm p-6">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-lg font-semibold text-gray-900">Employee Overview</h2>
                  <div className="flex items-center space-x-2">
                    <button className="text-sm bg-[#003A9B] text-white px-4 py-2 rounded hover:bg-[#0046bd] transition-colors">
                      Add Employee
                    </button>
                    <select className="text-sm border border-gray-300 rounded px-3 py-1">
                      <option>All Businesses</option>
                      <option>Downtown Café</option>
                      <option>Uptown Restaurant</option>
                      <option>Lakeside Bar</option>
                    </select>
                  </div>
                </div>
                
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Name
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Business
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Hours Worked (Week)
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Pay Rate
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Payroll Cost
                        </th>
                        <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">Jane Smith</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">Downtown Café</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">32</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">$15.50/hr</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">$496.00</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <a href="#" className="text-[#003A9B] hover:text-[#0046bd]">Edit</a>
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">John Doe</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">Uptown Restaurant</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">40</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">$18.00/hr</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">$720.00</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <a href="#" className="text-[#003A9B] hover:text-[#0046bd]">Edit</a>
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">Sarah Johnson</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">Lakeside Bar</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">25</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">$16.75/hr</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">$418.75</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <a href="#" className="text-[#003A9B] hover:text-[#0046bd]">Edit</a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                
                <div className="mt-4 bg-blue-50 p-3 rounded text-sm">
                  <div className="flex items-start">
                    <div className="text-blue-600 mr-2 mt-0.5">
                      <FontAwesomeIcon icon={faLightbulb} />
                    </div>
                    <p className="text-gray-700">
                      <span className="font-medium">AI Insight:</span> Three employees at Downtown Café have had reduced hours ({'>'}20% decrease) for 3 consecutive weeks.
                    </p>
                  </div>
                </div>
              </div>
              
              {/* AI Insights Section */}
              <div className="bg-white rounded-lg shadow-sm p-6">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-lg font-semibold text-gray-900">AI-Powered Business Insights</h2>
                </div>
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {/* Insight Card 1 */}
                  <div className="border border-blue-200 rounded-lg p-4 bg-blue-50">
                    <h3 className="font-medium text-gray-900 mb-2">High Payroll Cost at Lakeside Bar</h3>
                    <p className="text-sm text-gray-700 mb-3">
                      Your Lakeside Bar location has a 20% higher payroll-to-revenue ratio compared to your other businesses.
                    </p>
                    <div className="text-sm text-gray-700">
                      <span className="font-medium">Recommendation:</span> Consider merging the Tuesday and Wednesday evening shifts which have low customer traffic but full staffing.
                    </div>
                    <div className="mt-3 text-sm">
                      <span className="font-medium text-gray-700">Potential Savings:</span> <span className="text-green-600 font-medium">$320/week</span>
                    </div>
                  </div>
                  
                  {/* Insight Card 2 */}
                  <div className="border border-blue-200 rounded-lg p-4 bg-blue-50">
                    <h3 className="font-medium text-gray-900 mb-2">Employee Retention Risk</h3>
                    <p className="text-sm text-gray-700 mb-3">
                      Three employees at Downtown Café have had reduced hours ({'>'}20% decrease) for 3 consecutive weeks.
                    </p>
                    <div className="text-sm text-gray-700">
                      <span className="font-medium">Recommendation:</span> Schedule check-ins with these employees to address concerns and discuss future scheduling needs.
                    </div>
                    <div className="mt-3 text-sm">
                      <span className="font-medium text-gray-700">Risk Level:</span> <span className="text-orange-600 font-medium">Medium</span>
                    </div>
                  </div>
                  
                  {/* Insight Card 3 */}
                  <div className="border border-blue-200 rounded-lg p-4 bg-blue-50">
                    <h3 className="font-medium text-gray-900 mb-2">Revenue Opportunity</h3>
                    <p className="text-sm text-gray-700 mb-3">
                      Uptown Restaurant shows 35% higher traffic on Fridays but similar staffing to other weekdays.
                    </p>
                    <div className="text-sm text-gray-700">
                      <span className="font-medium">Recommendation:</span> Increase staffing by 2 employees during Friday dinner service to improve customer experience and turnover.
                    </div>
                    <div className="mt-3 text-sm">
                      <span className="font-medium text-gray-700">Potential Revenue Increase:</span> <span className="text-green-600 font-medium">$450/week</span>
                    </div>
                  </div>
                  
                  {/* Insight Card 4 */}
                  <div className="border border-blue-200 rounded-lg p-4 bg-blue-50">
                    <h3 className="font-medium text-gray-900 mb-2">Shift Imbalance Detected</h3>
                    <p className="text-sm text-gray-700 mb-3">
                      Downtown Café has inconsistent employee distribution across shifts, with mornings overstaffed relative to sales.
                    </p>
                    <div className="text-sm text-gray-700">
                      <span className="font-medium">Recommendation:</span> Rebalance morning shifts by moving 1 employee to afternoon peak hours.
                    </div>
                    <div className="mt-3 text-sm">
                      <span className="font-medium text-gray-700">Efficiency Improvement:</span> <span className="text-green-600 font-medium">12%</span>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Actions Panel */}
              <div className="bg-white rounded-lg shadow-sm p-6">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-lg font-semibold text-gray-900">Actions</h2>
                </div>
                
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
                  <button className="flex flex-col items-center justify-center bg-gray-50 hover:bg-blue-50 p-4 rounded-lg border border-gray-200 transition-colors">
                    <div className="text-[#003A9B] text-xl mb-2">+</div>
                    <div className="text-sm font-medium text-gray-700">Add Business</div>
                  </button>
                  
                  <button className="flex flex-col items-center justify-center bg-gray-50 hover:bg-blue-50 p-4 rounded-lg border border-gray-200 transition-colors">
                    <div className="text-[#003A9B] text-xl mb-2">+</div>
                    <div className="text-sm font-medium text-gray-700">Add Employee</div>
                  </button>
                  
                  <button className="flex flex-col items-center justify-center bg-gray-50 hover:bg-blue-50 p-4 rounded-lg border border-gray-200 transition-colors">
                    <div className="text-[#003A9B] text-xl mb-2">↔️</div>
                    <div className="text-sm font-medium text-gray-700">Assign Employee</div>
                  </button>
                  
                  <button className="flex flex-col items-center justify-center bg-gray-50 hover:bg-blue-50 p-4 rounded-lg border border-gray-200 transition-colors">
                    <div className="text-[#003A9B] text-xl mb-2">📊</div>
                    <div className="text-sm font-medium text-gray-700">Payroll Report</div>
                  </button>
                  
                  <button className="flex flex-col items-center justify-center bg-gray-50 hover:bg-blue-50 p-4 rounded-lg border border-gray-200 transition-colors">
                    <div className="text-[#003A9B] text-xl mb-2">📥</div>
                    <div className="text-sm font-medium text-gray-700">Export Data</div>
                  </button>
                </div>
              </div>
            </div>
          )}
          
          {activeSection === 'scheduling' && (
            <div className="space-y-6">
              {/* Scheduling & Shift Management content */}
              <div className="bg-white rounded-lg shadow-sm p-6">
                <div className="flex justify-between items-center">
                  <h1 className="text-2xl font-bold text-gray-900">Smart Scheduling & Shift Management</h1>
                  <div>
                    <select 
                      className="text-sm border border-gray-300 rounded px-3 py-2"
                      value={selectedBusiness}
                      onChange={handleBusinessChange}
                    >
                      {mockScheduleData.businesses.map(business => (
                        <option key={business.id} value={business.id}>
                          {business.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              
              {/* Schedule Grid */}
              <div className="bg-white rounded-lg shadow-sm overflow-hidden">
                <div className="grid grid-cols-8 bg-gray-100">
                  {/* Header row */}
                  <div className="p-4 font-medium border-b border-r border-gray-200 text-gray-700">
                    Employee
                  </div>
                  {days.map((day, index) => (
                    <div 
                      key={day} 
                      className="p-4 text-center font-medium border-b border-r border-gray-200 text-gray-700"
                    >
                      {dayLabels[index]}
                    </div>
                  ))}
                </div>
                
                {/* Employee rows */}
                {getFilteredEmployees().map(employee => (
                  <div key={employee.id} className="grid grid-cols-8">
                    <div className="p-4 border-b border-r border-gray-200 font-medium">
                      {employee.name}
                    </div>
                    {days.map(day => {
                      const shift = getShiftForCell(employee.id, day);
                      return (
                        <div 
                          key={`${employee.id}-${day}`} 
                          className={`p-2 border-b border-r border-gray-200 text-center cursor-pointer ${
                            shift ? 'bg-blue-50' : 'hover:bg-gray-50'
                          }`}
                          onClick={() => handleCellClick(employee.id, day)}
                        >
                          {shift ? (
                            <div className="text-sm p-2 bg-blue-100 rounded text-blue-800">
                              {shift.startTime} – {shift.endTime}
                            </div>
                          ) : (
                            <div className="text-xs text-gray-400 h-10 flex items-center justify-center">
                              + Add Shift
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
              
              {/* AI Assistant Panel */}
              <div className="bg-white rounded-lg shadow-sm p-6">
                <div className="flex items-center mb-4">
                  <FontAwesomeIcon icon={faRobot} className="text-blue-600 text-xl mr-3" />
                  <h2 className="text-lg font-semibold text-gray-900">AI Scheduling Assistant</h2>
                </div>
                
                <div className="space-y-4">
                  {getFilteredAiSuggestions().map(suggestion => (
                    <div 
                      key={suggestion.id} 
                      className="bg-blue-50 border border-blue-100 rounded-md p-4"
                    >
                      <p className="text-gray-800 mb-3">
                        {suggestion.message}
                      </p>
                      <div className="flex space-x-2">
                        {suggestion.actions.map(action => {
                          const icon = action.includes('Auto') 
                            ? faMagic 
                            : action.includes('Review') 
                              ? faListAlt
                              : action.includes('Notify')
                                ? faBell
                                : null;
                          
                          return (
                            <button 
                              key={action} 
                              className="text-sm bg-white border border-gray-300 text-gray-700 px-3 py-2 rounded hover:bg-gray-50 flex items-center"
                            >
                              {icon && <FontAwesomeIcon icon={icon} className="mr-2" />}
                              {action}
                            </button>
                          );
                        })}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              
              {/* AI Chat Assistant */}
              <div className="bg-white rounded-lg shadow-sm p-6 mt-6">
                <div className="flex items-center mb-4">
                  <FontAwesomeIcon icon={faRobot} className="text-blue-600 text-xl mr-3" />
                  <h2 className="text-lg font-semibold text-gray-900">Ask WorkFission AI</h2>
                </div>
                
                <div className="border border-gray-200 rounded-lg p-4 max-h-96 overflow-y-auto mb-4">
                  <div className="space-y-4">
                    {chatMessages.map((message, index) => (
                      <div key={index} className={`flex ${message.sender === 'user' ? 'justify-end' : ''}`}>
                        <div className={`${message.sender === 'ai' ? 'bg-blue-100' : 'bg-gray-100'} rounded-lg p-3 max-w-[80%]`}>
                          <p className="text-gray-800">{message.text}</p>
                        </div>
                      </div>
                    ))}
                    {isProcessing && (
                      <div className="flex">
                        <div className="bg-blue-100 rounded-lg p-3">
                          <p className="text-gray-800">Thinking...</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                
                <div className="flex">
                  <input 
                    type="text" 
                    placeholder="Ask any scheduling question..." 
                    className="flex-1 border border-gray-300 rounded-l-md px-4 py-2 focus:outline-none focus:ring-1 focus:ring-[#003A9B] focus:border-[#003A9B]" 
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' && newMessage.trim()) {
                        handleSendMessage(newMessage);
                      }
                    }}
                  />
                  <button 
                    className="bg-[#003A9B] text-white px-4 py-2 rounded-r-md hover:bg-[#0046bd]"
                    onClick={() => {
                      if (newMessage.trim()) {
                        handleSendMessage(newMessage);
                      }
                    }}
                    disabled={isProcessing || !newMessage.trim()}
                  >
                    <FontAwesomeIcon icon={faRobot} className="mr-2" />
                    Ask AI
                  </button>
                </div>
                
                <div className="mt-4">
                  <p className="text-sm text-gray-500">Suggested questions:</p>
                  <div className="flex flex-wrap gap-2 mt-2">
                    <button 
                      className="text-xs bg-gray-100 hover:bg-gray-200 px-3 py-1 rounded-full text-gray-600"
                      onClick={() => handleSuggestedQuestion("Which employees are working overtime?")}
                    >
                      Which employees are working overtime?
                    </button>
                    <button 
                      className="text-xs bg-gray-100 hover:bg-gray-200 px-3 py-1 rounded-full text-gray-600"
                      onClick={() => handleSuggestedQuestion("Can you optimize shifts for next week?")}
                    >
                      Optimize shifts for next week
                    </button>
                    <button 
                      className="text-xs bg-gray-100 hover:bg-gray-200 px-3 py-1 rounded-full text-gray-600"
                      onClick={() => handleSuggestedQuestion("Who has the most shifts this month?")}
                    >
                      Who has the most shifts this month?
                    </button>
                    <button 
                      className="text-xs bg-gray-100 hover:bg-gray-200 px-3 py-1 rounded-full text-gray-600"
                      onClick={() => handleSuggestedQuestion("Are there any scheduling conflicts?")}
                    >
                      Are there any scheduling conflicts?
                    </button>
                    <button 
                      className="text-xs bg-gray-100 hover:bg-gray-200 px-3 py-1 rounded-full text-gray-600"
                      onClick={() => handleSuggestedQuestion("Are there any uncovered shifts next week?")}
                    >
                      Are there any uncovered shifts next week?
                    </button>
                    <button 
                      className="text-xs bg-gray-100 hover:bg-gray-200 px-3 py-1 rounded-full text-gray-600"
                      onClick={() => handleSuggestedQuestion("Who's on vacation soon?")}
                    >
                      Who's on vacation soon?
                    </button>
                    <button 
                      className="text-xs bg-gray-100 hover:bg-gray-200 px-3 py-1 rounded-full text-gray-600"
                      onClick={() => handleSuggestedQuestion("What are our peak hours and is staffing aligned?")}
                    >
                      What are our peak hours?
                    </button>
                    <button 
                      className="text-xs bg-gray-100 hover:bg-gray-200 px-3 py-1 rounded-full text-gray-600"
                      onClick={() => handleSuggestedQuestion("Who are our top performing employees?")}
                    >
                      Who are our top performers?
                    </button>
                  </div>
                </div>
              </div>
              
              {/* Bottom Action Bar */}
              <div className="bg-gray-100 rounded-lg p-4 flex justify-end space-x-3 mt-6">
                <button className="px-4 py-2 border border-gray-300 rounded text-gray-700 hover:bg-gray-50">
                  Cancel
                </button>
                <button className="px-4 py-2 bg-[#003A9B] text-white rounded hover:bg-[#0046bd]">
                  Save Schedule
                </button>
              </div>
            </div>
          )}
          
          {/* Other section content based on activeSection */}
          {activeSection === 'jobs' && (
            <div className="space-y-6">
              {/* Job Posting & Hiring Section Header */}
              <div className="bg-white rounded-lg shadow-sm p-6">
                <h1 className="text-2xl font-bold text-gray-900">Job Posting & Hiring</h1>
                <p className="text-gray-600 mt-1">Create job postings, track applicants, and manage the hiring process</p>
                
                {/* Section Navigation */}
                <div className="flex border-b border-gray-200 mt-6">
                  <button 
                    className={`py-3 px-6 font-medium text-sm ${selectedJobTab === 'posting' 
                      ? 'text-[#003A9B] border-b-2 border-[#003A9B]' 
                      : 'text-gray-500 hover:text-gray-700'}`}
                    onClick={() => setSelectedJobTab('posting')}
                  >
                    Job Posting
                  </button>
                  <button 
                    className={`py-3 px-6 font-medium text-sm ${selectedJobTab === 'hiring' 
                      ? 'text-[#003A9B] border-b-2 border-[#003A9B]' 
                      : 'text-gray-500 hover:text-gray-700'}`}
                    onClick={() => setSelectedJobTab('hiring')}
                  >
                    Hiring & Onboarding
                  </button>
                </div>
              </div>
              
              {/* Job Posting Section */}
              {selectedJobTab === 'posting' && (
                <div className="space-y-6">
                  {/* AI Job Creator */}
                  <div className="bg-white rounded-lg shadow-sm p-6">
                    <div className="flex items-center mb-4">
                      <FontAwesomeIcon icon={faRobot} className="text-blue-600 text-xl mr-3" />
                      <h2 className="text-lg font-semibold text-gray-900">AI-Powered Job Creator</h2>
                    </div>
                    
                    <p className="text-gray-600 mb-4">
                      Describe the job you want to create in natural language, and our AI will generate a complete job posting for you.
                    </p>
                    
                    {/* AI Chat Interface */}
                    <div className="border border-gray-200 rounded-lg p-4 max-h-60 overflow-y-auto mb-4">
                      <div className="space-y-4">
                        {jobChatMessages.map((message, index) => (
                          <div key={index} className={`flex ${message.sender === 'user' ? 'justify-end' : ''}`}>
                            <div 
                              className={`${message.sender === 'ai' 
                                ? 'bg-blue-100' 
                                : 'bg-gray-100'} rounded-lg p-3 max-w-[80%]`}
                            >
                              <p className="text-gray-800">{message.text}</p>
                            </div>
                          </div>
                        ))}
                        {isProcessingJobAI && (
                          <div className="flex">
                            <div className="bg-blue-100 rounded-lg p-3">
                              <p className="text-gray-800">Thinking...</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    
                    {/* AI Chat Input */}
                    <div className="flex">
                      <input 
                        type="text" 
                        placeholder="Describe the job you want to create..." 
                        className="flex-1 border border-gray-300 rounded-l-md px-4 py-2 focus:outline-none focus:ring-1 focus:ring-[#003A9B] focus:border-[#003A9B]" 
                        value={jobChatInput}
                        onChange={(e) => setJobChatInput(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter' && jobChatInput.trim()) {
                            handleJobChatSend(jobChatInput);
                          }
                        }}
                      />
                      <button 
                        className="bg-[#003A9B] text-white px-4 py-2 rounded-r-md hover:bg-[#0046bd]"
                        onClick={() => {
                          if (jobChatInput.trim()) {
                            handleJobChatSend(jobChatInput);
                          }
                        }}
                        disabled={isProcessingJobAI || !jobChatInput.trim()}
                      >
                        <FontAwesomeIcon icon={faRobot} className="mr-2" />
                        Create with AI
                      </button>
                    </div>
                    
                    {/* Example Prompts */}
                    <div className="mt-4">
                      <p className="text-sm text-gray-500">Try saying:</p>
                      <div className="flex flex-wrap gap-2 mt-2">
                        <button 
                          className="text-xs bg-gray-100 hover:bg-gray-200 px-3 py-1 rounded-full text-gray-600"
                          onClick={() => setJobChatInput("Create a part-time cashier job for Green Grocery. $15/hour, morning shifts only.")}
                        >
                          "Create a part-time cashier job for Green Grocery. $15/hour, morning shifts only."
                        </button>
                        <button 
                          className="text-xs bg-gray-100 hover:bg-gray-200 px-3 py-1 rounded-full text-gray-600"
                          onClick={() => setJobChatInput("Post a delivery driver job for Urban Tech. $18/hr, full-time, must have a valid license.")}
                        >
                          "Post a delivery driver job for Urban Tech. $18/hr, full-time, must have a valid license."
                        </button>
                      </div>
                    </div>
                  </div>
                  
                  {/* Job Application Form */}
                  {jobCreationMode && (
                    <div className="mt-6 border-t border-gray-200 pt-6">
                      <div className="bg-white rounded-lg">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                          {/* Left Column - Job Details */}
                          <div className="space-y-4">
                            <h3 className="text-lg font-semibold text-gray-900">Job Details</h3>
                            
                            <div>
                              <label className="block text-sm font-medium text-gray-700 mb-1">
                                Job Title
                              </label>
                              <input
                                type="text"
                                name="title"
                                value={jobFormData.title}
                                onChange={handleJobFormChange}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-[#003A9B] focus:border-[#003A9B]"
                              />
                            </div>
                            
                            <div>
                              <label className="block text-sm font-medium text-gray-700 mb-1">
                                Business
                              </label>
                              <select
                                name="business"
                                value={jobFormData.business}
                                onChange={handleJobFormChange}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-[#003A9B] focus:border-[#003A9B]"
                              >
                                <option value="">Select Business</option>
                                <option value="Cafe Sunshine">Cafe Sunshine</option>
                                <option value="Urban Tech Solutions">Urban Tech Solutions</option>
                                <option value="Green Grocery">Green Grocery</option>
                              </select>
                            </div>
                            
                            <div>
                              <label className="block text-sm font-medium text-gray-700 mb-1">
                                Location
                              </label>
                              <input
                                type="text"
                                name="location"
                                value={jobFormData.location}
                                onChange={handleJobFormChange}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-[#003A9B] focus:border-[#003A9B]"
                              />
                            </div>
                            
                            <div>
                              <label className="block text-sm font-medium text-gray-700 mb-1">
                                Pay Rate
                              </label>
                              <input
                                type="text"
                                name="payRate"
                                value={jobFormData.payRate}
                                onChange={handleJobFormChange}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-[#003A9B] focus:border-[#003A9B]"
                                placeholder="e.g. $15/hour"
                              />
                            </div>
                            
                            <div>
                              <label className="block text-sm font-medium text-gray-700 mb-1">
                                Job Type
                              </label>
                              <select
                                name="jobType"
                                value={jobFormData.jobType}
                                onChange={handleJobFormChange}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-[#003A9B] focus:border-[#003A9B]"
                              >
                                <option value="Full-time">Full-time</option>
                                <option value="Part-time">Part-time</option>
                                <option value="Contract">Contract</option>
                                <option value="Temporary">Temporary</option>
                              </select>
                            </div>
                            
                            <div>
                              <label className="block text-sm font-medium text-gray-700 mb-1">
                                Shift Schedule
                              </label>
                              <input
                                type="text"
                                name="shiftSchedule"
                                value={jobFormData.shiftSchedule}
                                onChange={handleJobFormChange}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-[#003A9B] focus:border-[#003A9B]"
                                placeholder="e.g. Morning shifts (6AM-2PM)"
                              />
                            </div>
                          </div>
                          
                          {/* Right Column - Additional Details */}
                          <div className="space-y-4">
                            <h3 className="text-lg font-semibold text-gray-900">Additional Details</h3>
                            
                            <div>
                              <label className="block text-sm font-medium text-gray-700 mb-1">
                                Benefits
                              </label>
                              <textarea
                                name="benefits"
                                value={jobFormData.benefits}
                                onChange={handleJobFormChange}
                                rows="3"
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-[#003A9B] focus:border-[#003A9B]"
                                placeholder="List the benefits offered..."
                              />
                            </div>
                            
                            <div>
                              <label className="block text-sm font-medium text-gray-700 mb-1">
                                Job Description
                              </label>
                              <textarea
                                name="description"
                                value={jobFormData.description}
                                onChange={handleJobFormChange}
                                rows="4"
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-[#003A9B] focus:border-[#003A9B]"
                              />
                            </div>
                            
                            <div>
                              <label className="block text-sm font-medium text-gray-700 mb-1">
                                Requirements
                              </label>
                              <textarea
                                name="requirements"
                                value={jobFormData.requirements}
                                onChange={handleJobFormChange}
                                rows="4"
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-[#003A9B] focus:border-[#003A9B]"
                                placeholder="List job requirements..."
                              />
                            </div>
                            
                            {/* Application Settings */}
                            <div className="bg-gray-50 p-4 rounded-lg">
                              <h4 className="text-sm font-medium text-gray-900 mb-3">Application Settings</h4>
                              <div className="space-y-2">
                                <label className="flex items-center">
                                  <input
                                    type="checkbox"
                                    className="rounded border-gray-300 text-[#003A9B] focus:ring-[#003A9B]"
                                    defaultChecked
                                  />
                                  <span className="ml-2 text-sm text-gray-700">Require Resume</span>
                                </label>
                                <label className="flex items-center">
                                  <input
                                    type="checkbox"
                                    className="rounded border-gray-300 text-[#003A9B] focus:ring-[#003A9B]"
                                    defaultChecked
                                  />
                                  <span className="ml-2 text-sm text-gray-700">Require Cover Letter</span>
                                </label>
                                <label className="flex items-center">
                                  <input
                                    type="checkbox"
                                    className="rounded border-gray-300 text-[#003A9B] focus:ring-[#003A9B]"
                                    defaultChecked
                                  />
                                  <span className="ml-2 text-sm text-gray-700">Allow One-Click Apply</span>
                                </label>
                              </div>
                            </div>
                            
                            {/* AI-Powered Features */}
                            <div className="bg-blue-50 p-4 rounded-lg">
                              <h4 className="text-sm font-medium text-gray-900 mb-3">AI-Powered Features</h4>
                              <div className="space-y-2">
                                <label className="flex items-center">
                                  <input
                                    type="checkbox"
                                    className="rounded border-gray-300 text-[#003A9B] focus:ring-[#003A9B]"
                                    defaultChecked
                                  />
                                  <span className="ml-2 text-sm text-gray-700">AI Resume Screening</span>
                                </label>
                                <label className="flex items-center">
                                  <input
                                    type="checkbox"
                                    className="rounded border-gray-300 text-[#003A9B] focus:ring-[#003A9B]"
                                    defaultChecked
                                  />
                                  <span className="ml-2 text-sm text-gray-700">Skills Match Analysis</span>
                                </label>
                                <label className="flex items-center">
                                  <input
                                    type="checkbox"
                                    className="rounded border-gray-300 text-[#003A9B] focus:ring-[#003A9B]"
                                    defaultChecked
                                  />
                                  <span className="ml-2 text-sm text-gray-700">Automated Initial Screening</span>
                                </label>
                                <label className="flex items-center">
                                  <input
                                    type="checkbox"
                                    className="rounded border-gray-300 text-[#003A9B] focus:ring-[#003A9B]"
                                    defaultChecked
                                  />
                                  <span className="ml-2 text-sm text-gray-700">Smart Interview Scheduling</span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                        {/* Preview Section */}
                        <div className="mt-6 border-t border-gray-200 pt-6">
                          <h3 className="text-lg font-semibold text-gray-900 mb-4">Job Posting Preview</h3>
                          <div className="bg-gray-50 p-6 rounded-lg">
                            <h4 className="text-xl font-semibold text-gray-900 mb-2">{jobFormData.title}</h4>
                            <div className="text-sm text-gray-600 mb-4">
                              {jobFormData.business} • {jobFormData.location} • {jobFormData.jobType}
                            </div>
                            <div className="space-y-4">
                              <div>
                                <h5 className="text-sm font-medium text-gray-900">Pay Rate</h5>
                                <p className="text-sm text-gray-700">{jobFormData.payRate}</p>
                              </div>
                              <div>
                                <h5 className="text-sm font-medium text-gray-900">Schedule</h5>
                                <p className="text-sm text-gray-700">{jobFormData.shiftSchedule}</p>
                              </div>
                              <div>
                                <h5 className="text-sm font-medium text-gray-900">Benefits</h5>
                                <p className="text-sm text-gray-700">{jobFormData.benefits}</p>
                              </div>
                              <div>
                                <h5 className="text-sm font-medium text-gray-900">Description</h5>
                                <p className="text-sm text-gray-700">{jobFormData.description}</p>
                              </div>
                              <div>
                                <h5 className="text-sm font-medium text-gray-900">Requirements</h5>
                                <p className="text-sm text-gray-700">{jobFormData.requirements}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                        {/* Action Buttons */}
                        <div className="mt-6 flex justify-end space-x-3">
                          <button
                            onClick={handleCancelJobCreation}
                            className="px-4 py-2 border border-gray-300 rounded text-gray-700 hover:bg-gray-50"
                          >
                            Cancel
                          </button>
                          <button
                            onClick={handlePostJob}
                            className="px-4 py-2 bg-[#003A9B] text-white rounded hover:bg-[#0046bd]"
                          >
                            Post Job
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              
              {/* Hiring & Onboarding Dashboard */}
              {selectedJobTab === 'hiring' && (
                <div className="space-y-6">
                  {/* AI Hiring Assistant Panel */}
                  <div className="bg-white rounded-lg shadow-sm p-6">
                    <div className="flex items-center mb-4">
                      <FontAwesomeIcon icon={faRobot} className="text-blue-600 text-xl mr-3" />
                      <h2 className="text-lg font-semibold text-gray-900">AI Hiring Assistant</h2>
                    </div>
                    
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      {mockJobsData.hiringInsights.map(insight => (
                        <div 
                          key={insight.id} 
                          className={`bg-blue-50 border border-blue-100 rounded-md p-4 ${
                            insight.type === 'onboarding' ? 'border-l-4 border-l-green-500' : 
                            insight.type === 'applicant' ? 'border-l-4 border-l-blue-500' : ''
                          }`}
                        >
                          <p className="text-gray-800 mb-3">
                            {insight.message}
                          </p>
                          {insight.actionable && (
                            <button className="text-sm bg-white border border-gray-300 text-gray-700 px-3 py-1 rounded hover:bg-gray-50">
                              {insight.action}
                            </button>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  
                  {/* Candidates Overview */}
                  <div className="bg-white rounded-lg shadow-sm p-6">
                    <div className="flex justify-between items-center mb-4">
                      <h2 className="text-lg font-semibold text-gray-900">Candidates Overview</h2>
                      <div className="flex items-center space-x-2">
                        <select 
                          className="text-sm border border-gray-300 rounded px-3 py-1"
                          value={selectedCandidateFilter}
                          onChange={(e) => setSelectedCandidateFilter(e.target.value)}
                        >
                          <option value="all">All Candidates</option>
                          <option value="applied">Applied</option>
                          <option value="interviewed">Interviewed</option>
                          <option value="shortlisted">Shortlisted</option>
                          <option value="hired">Hired</option>
                          <option value="rejected">Rejected</option>
                        </select>
                      </div>
                    </div>
                    
                    <div className="overflow-x-auto">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Name
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Applied Role
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Business
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Status
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Hourly Rate
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Date Applied
                            </th>
                            <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {getFilteredCandidates().map(candidate => (
                            <tr key={candidate.id}>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="text-sm font-medium text-gray-900">{candidate.name}</div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-900">{candidate.appliedRole}</div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-900">{candidate.appliedBusiness}</div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <span 
                                  className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                    candidate.status === 'Hired' ? 'bg-green-100 text-green-800' :
                                    candidate.status === 'Rejected' ? 'bg-red-100 text-red-800' :
                                    candidate.status === 'Shortlisted' ? 'bg-yellow-100 text-yellow-800' :
                                    candidate.status === 'Interviewed' ? 'bg-blue-100 text-blue-800' :
                                    'bg-gray-100 text-gray-800'
                                  }`}
                                >
                                  {candidate.status}
                                </span>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-900">{candidate.hourlyRate}</div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-900">{candidate.dateApplied}</div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                <button className="text-[#003A9B] hover:text-[#0046bd] mr-3">
                                  View Resume
                                </button>
                                {candidate.status !== 'Hired' && candidate.status !== 'Rejected' && (
                                  <>
                                    {candidate.status === 'Applied' && (
                                      <button className="text-gray-500 hover:text-gray-700 mr-3">
                                        Schedule Interview
                                      </button>
                                    )}
                                    <button className="text-green-600 hover:text-green-800">
                                      Mark as Hired
                                    </button>
                                  </>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  
                  {/* Onboarding Progress Section */}
                  <div className="bg-white rounded-lg shadow-sm p-6">
                    <div className="flex items-center mb-4">
                      <h2 className="text-lg font-semibold text-gray-900">Onboarding Progress</h2>
                    </div>
                    
                    {mockJobsData.candidates.filter(c => c.status === 'Hired').length > 0 ? (
                      <div className="space-y-6">
                        {mockJobsData.candidates
                          .filter(c => c.status === 'Hired')
                          .map(candidate => (
                            <div key={candidate.id} className="border border-gray-200 rounded-lg p-4">
                              <div className="flex justify-between items-center mb-3">
                                <div>
                                  <h3 className="font-medium text-gray-900">{candidate.name}</h3>
                                  <p className="text-sm text-gray-500">
                                    {candidate.appliedRole} at {candidate.appliedBusiness}
                                  </p>
                                </div>
                                <span className="bg-green-100 text-green-800 text-xs px-2 py-1 rounded-full">
                                  Hired on {candidate.dateApplied}
                                </span>
                              </div>
                              
                              <div className="space-y-2">
                                <div className="flex items-center">
                                  <span className={`w-5 h-5 rounded-full mr-2 flex items-center justify-center ${
                                    candidate.onboarding.w2Submitted ? 'bg-green-500 text-white' : 'bg-gray-200'
                                  }`}>
                                    {candidate.onboarding.w2Submitted && (
                                      <FontAwesomeIcon icon={faCircleCheck} className="text-xs" />
                                    )}
                                  </span>
                                  <span className="text-sm text-gray-700">W-2 / Tax Forms</span>
                                </div>
                                
                                <div className="flex items-center">
                                  <span className={`w-5 h-5 rounded-full mr-2 flex items-center justify-center ${
                                    candidate.onboarding.idVerified ? 'bg-green-500 text-white' : 'bg-gray-200'
                                  }`}>
                                    {candidate.onboarding.idVerified && (
                                      <FontAwesomeIcon icon={faCircleCheck} className="text-xs" />
                                    )}
                                  </span>
                                  <span className="text-sm text-gray-700">ID Verification</span>
                                </div>
                                
                                <div className="flex items-center">
                                  <span className={`w-5 h-5 rounded-full mr-2 flex items-center justify-center ${
                                    candidate.onboarding.directDepositSetup ? 'bg-green-500 text-white' : 'bg-gray-200'
                                  }`}>
                                    {candidate.onboarding.directDepositSetup && (
                                      <FontAwesomeIcon icon={faCircleCheck} className="text-xs" />
                                    )}
                                  </span>
                                  <span className="text-sm text-gray-700">Direct Deposit Setup</span>
                                </div>
                                
                                <div className="flex items-center">
                                  <span className={`w-5 h-5 rounded-full mr-2 flex items-center justify-center ${
                                    candidate.onboarding.offerSigned ? 'bg-green-500 text-white' : 'bg-gray-200'
                                  }`}>
                                    {candidate.onboarding.offerSigned && (
                                      <FontAwesomeIcon icon={faCircleCheck} className="text-xs" />
                                    )}
                                  </span>
                                  <span className="text-sm text-gray-700">Offer Letter Signed</span>
                                </div>
                              </div>
                              
                              <div className="mt-4 flex justify-between">
                                <div>
                                  {Object.values(candidate.onboarding).every(v => v) ? (
                                    <span className="text-green-600 text-sm font-medium">
                                      ✓ All onboarding tasks completed
                                    </span>
                                  ) : (
                                    <span className="text-yellow-600 text-sm font-medium">
                                      {Object.values(candidate.onboarding).filter(v => v).length} of 4 tasks completed
                                    </span>
                                  )}
                                </div>
                                <button className="text-sm text-[#003A9B] hover:text-[#0046bd]">
                                  Send Reminder
                                </button>
                              </div>
                            </div>
                          ))}
                      </div>
                    ) : (
                      <div className="text-center py-8">
                        <p className="text-gray-500">No hired candidates to show onboarding progress.</p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Modals */}
      {/* Custom Date Range Modal */}
      {isDateRangeModalOpen && (
        <DateRangeModal
          isOpen={isDateRangeModalOpen}
          onClose={() => setIsDateRangeModalOpen(false)}
          onApply={handleApplyDateRange}
        />
      )}
      
      {/* Shift Modal */}
      {isShiftModalOpen && (
        <ShiftModal
          isOpen={isShiftModalOpen}
          shift={currentShift}
          onClose={() => setIsShiftModalOpen(false)}
          onSave={handleSaveShift}
          mockScheduleData={mockScheduleData}
          days={days}
          dayLabels={dayLabels}
        />
      )}
    </div>
  );
};

export default EmployerDashboard; 