import React from 'react';
import { Link } from 'react-router-dom';

const AuthLayout = ({ children, title, subtitle }) => {
  return (
    <div className="min-h-screen flex flex-col justify-between bg-gray-50">
      {/* Main Content */}
      <div className="flex-1 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        {/* Logo */}
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <Link to="/" className="flex justify-center">
            <span className="text-2xl font-bold text-[#003A9B]">WorkFission</span>
          </Link>
        </div>

        {/* Header */}
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            {title}
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            {subtitle}
          </p>
        </div>

        {/* Form Card */}
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow-lg sm:rounded-lg sm:px-10">
            {children}
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="py-4 text-center text-sm text-gray-500">
        <p className="space-x-1">
          <span>By creating an account or signing in, you understand and agree to WorkFission's</span>
          <Link to="/terms" className="text-[#003A9B] hover:text-[#0046bd]">Terms</Link>
          <span>.</span>
          <span>You also acknowledge our</span>
          <Link to="/cookies" className="text-[#003A9B] hover:text-[#0046bd]">Cookie</Link>
          <span>and</span>
          <Link to="/privacy" className="text-[#003A9B] hover:text-[#0046bd]">Privacy</Link>
          <span>policies.</span>
        </p>
      </footer>
    </div>
  );
};

export default AuthLayout; 