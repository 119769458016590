import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faUsers, faCogs } from '@fortawesome/free-solid-svg-icons';

const BenefitCard = ({ icon, title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-lg">
    <div className="text-[#003A9B] text-3xl mb-4">
      <FontAwesomeIcon icon={icon} />
    </div>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const Benefits = () => {
  const benefits = [
    {
      icon: faBuilding,
      title: "For Business Owners",
      description: "Quick job posting, efficient hiring, seamless scheduling, and automated payroll management all in one place."
    },
    {
      icon: faUsers,
      title: "For Workers",
      description: "Find flexible jobs that fit your schedule with secure and timely payments, all while building your professional network."
    },
    {
      icon: faCogs,
      title: "Smart Workforce Management",
      description: "From hiring to payroll in a single place. Streamline your operations with our integrated platform."
    }
  ];

  return (
    <section id="benefits" className="py-16 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900">Why Choose WorkFission?</h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {benefits.map((benefit, index) => (
            <BenefitCard key={index} {...benefit} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Benefits;
