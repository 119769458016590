import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faClipboardCheck, faCalendarCheck } from '@fortawesome/free-solid-svg-icons';

const StepCard = ({ icon, title, description, step }) => (
  <div className="relative flex flex-col items-center p-6 bg-white rounded-lg shadow-lg">
    <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 bg-[#003A9B] text-white w-8 h-8 rounded-full flex items-center justify-center font-bold">
      {step}
    </div>
    <div className="text-[#FF6600] text-3xl mb-4 mt-4">
      <FontAwesomeIcon icon={icon} />
    </div>
    <h3 className="text-xl font-semibold mb-2 text-center">{title}</h3>
    <p className="text-gray-600 text-center">{description}</p>
  </div>
);

const HowItWorks = () => {
  const steps = [
    {
      icon: faUserPlus,
      title: "Sign Up",
      description: "Register and create your profile."
    },
    {
      icon: faClipboardCheck,
      title: "Post Jobs / Apply",
      description: "Employers post jobs and employees apply."
    },
    {
      icon: faCalendarCheck,
      title: "Hire / Schedule & Get Paid",
      description: "Manage hiring, Schedule and payments seamlessly."
    }
  ];

  return (
    <section id="how-it-works" className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900">How It Works</h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12 mt-8">
          {steps.map((step, index) => (
            <StepCard key={index} {...step} step={index + 1} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
