import React from 'react';
import { useNavigate } from 'react-router-dom';
import businessImage from '../../assets/images/business.jpg';

const Hero = () => {
  const navigate = useNavigate();

  return (
    <section id="hero" className="bg-[#1C4E80] text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div className="space-y-6">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight">
              Hire, Schedule, & Pay Part-Time Staff Seamlessly
            </h1>
            <p className="text-lg md:text-xl text-gray-300">
              Streamline your workforce management with our comprehensive platform designed for modern businesses and job seekers.
            </p>
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
              <button
                onClick={() => navigate('/employer-signin')}
                className="bg-[#FF6600] text-white px-8 py-3 rounded-md hover:bg-[#ff7519] transition duration-300"
              >
                Start Hiring Now
              </button>
              <button
                onClick={() => navigate('/user-signin')}
                className="bg-white text-[#1C4E80] px-8 py-3 rounded-md hover:bg-gray-100 transition duration-300"
              >
                Find a Job
              </button>
            </div>
          </div>
          <div className="hidden md:block">
            <img
              src={businessImage}
              alt="Business professionals collaborating"
              className="rounded-lg shadow-xl w-full h-auto object-cover"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
